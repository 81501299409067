angular.module('admin.controllers')
.controller('UserCtrl',
	function($scope, $http, $routeParams, $location, $window, $timeout, User, Debtor, Alerts, Alerter, Charges, BankAccounts, CompanyReport) {
		//routes
		if($routeParams.debtorId) { $scope.previous = "debtor"; $scope.param = $routeParams.debtorId; }
		else { $scope.previous = "users"; }

		$scope.uploadText = "Upload";
		$scope.getUser = function() {
			$scope.user = User.get({ userName: $routeParams.username }, function() {
				$scope.scores = {
					creditScore: $scope.user.creditScore,
					creditGuide: $scope.user.creditGuide,
					userId: $scope.user.id
				};
				$scope.oldScores = angular.copy($scope.scores);
			});
		};
		$scope.getUser();


		$scope.outstanding = Charges.outstanding({ userName: $routeParams.username });
		$scope.bankAccounts = BankAccounts.query({ userName: $routeParams.username });

		$scope.verifyBankAccount = function(account) {
			BankAccounts.verify({ id: account.id }, function() {
				$scope.bankAccounts = BankAccounts.query({ userName: $routeParams.username });
			});
		}

		$scope.billingDetails = function() {
			$location.path('billing/' + $scope.user.userName);
		};

		$scope.saveScores = function() {
			User.setScores($scope.scores, function(success) {
				$scope.oldScores = angular.copy($scope.scores);
				$scope.editScores = !$scope.editScores;
			}, function(error) {
				$scope.cancelScores();
			});
		}

		$scope.cancelScores = function() {
			$scope.editScores = false;
			$scope.scores = angular.copy($scope.oldScores);
		};

		$scope.verify = function() {
			if(!$scope.user.emailConfirmed || !$scope.user.phoneNumberConfirmed) {
				var message;
				if(!$scope.user.emailConfirmed && !$scope.user.phoneNumberConfirmed) {
					message = Alerts.confirm({
						header: 'Phone and Email not verified',
						message: "User has not verified by text and email. Are you sure you want to verify this user?"
					});
				} else if(!$scope.user.emailConfirmed) {
					message = Alerts.confirm({
						header: 'Email not verified',
						message: "User has not verified by email. Are you sure you want to verify this user?"
					});
				} else {
					message = Alerts.confirm({
						header: 'Phone not verified',
						message: "User has not verified by text. Are you sure you want to verify this user?"
					});
				}

				message.result.then(function() {
					finishVerify()
				});
			} else {
				finishVerify();
			}
		};

		$scope.suspend = function() {
			var message = Alerts.confirm({
				header: 'Suspending User',
				message: "Are you sure you want to suspend this user?"
			});

			message.result.then(function() {
				finishSuspend()
			});
		};

		function finishSuspend() {
			$scope.user.$suspend(function() {
				$scope.user.suspended = true;
			});
		}

		$scope.vatExempt = function() {
			User.vatExempt({ userName: $routeParams.username }, function() {
				$scope.user.vatExempt = true;
			});
		};

		function finishVerify() {
			$scope.user.$verify(function() {
				$scope.user.verified = true;
			});
		}

		$scope.remove = function() {
			var message = Alerts.confirm({
				header: 'Delete',
				message: 'Are you sure you want to delete this user?'
			});

			message.result.then(function() {
				$scope.user.$remove(function(data) {
					Alerts.success({ message: 'User Deleted', header: 'User Deleted' });
					$location.path('/');
				});
			});
		};

		$scope.companyReport = function() {
			if($scope.user.companyReport) {
				$window.open($scope.user.companyReport.url);
			} else {
				Alerts.warning({
					header: 'No Report Available',
					message: "No Company Report available at the moment."
				});
			}
		};

		$scope.resetPassword = function() {
			Alerts.resetPassword($scope.user);
		};

		$scope.deleteDocument = function(doc) {
			doc.companyNumber = $scope.user.reportIdentifier;
			CompanyReport.remove(doc, function() {
				$scope.user.companyReport = null;
			});
		};

		$scope.uploadFile = function(files){
			var fd = new FormData();
			fd.append("file", files[0]);
			$scope.fileData = fd;
			$scope.file = files[0];

			$scope.$apply();
		};

		$scope.upload = function () {
			if ($scope.file === undefined) {
				Alerts.warning({
					header: "No file selected",
					message: "Please select a document to upload."
				});
			} else {
				$scope.uploadText = "Uploading...";
				var url = '/api/admin/companyreport/upload/' + $scope.user.reportIdentifier;

				$http.post(url, $scope.fileData, {
					headers: { 'Content-Type': undefined },
					transformRequest: angular.identity
				}).success(function(result) {
					$scope.uploadText = "Upload";
					$scope.file = undefined;
					document.getElementById('styled-finputs-example').value = null;
					$scope.getUser();
					$scope.uploadSuccess = true;
					$timeout(function() {
						$scope.uploadSuccess = null;
					}, 2000);
				}).error(function(err) {
					$scope.uploadText = "Upload";
					$scope.file = undefined;
				});
			}
		};
	}
);