angular.module('inv.directives')
.directive('moneyField', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/inputs/money-field.html',
		scope: {
			required: '@',
			field: '=',
			label: '@',
			placeholder: '@',
			message: '=',
			name: '@',
			blur: '&'
		}
	}
});