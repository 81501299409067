angular.module('buyer.controllers')
.controller('DealsCtrl',
	function($scope, Deals, $location) {
		$scope.closed = false;
		$scope.ct = 1;
		$scope.rowsPerPage = 10;
		$scope.searchType = 1;
		$scope.searchText = '';
		$scope.sort = { active: 0, ascending: false };
		$scope.portfolio = { mode: "1" };

		$scope.update = function() {
			if($scope.closed) {
				getDealDone($scope.ct);
			} else {
				getDealOpen($scope.ct);
			}
		};
		$scope.update();

		// when searching will return the first page
		$scope.search = function() {
			$scope.ct = 1;
			$scope.$broadcast('reset-pagination-directive');
			$scope.update();
		};

		$scope.fetchRows = function(ct) {
			$scope.ct = ct;
			if ($scope.closed) {
				getDealDone(ct);
			} else {
				getDealOpen(ct);
			}
		};

		$scope.getIcon = function(column) {
			if ($scope.sort.active === column) {
				return $scope.sort.ascending ? 'fa-chevron-down' : 'fa-chevron-up';
			}
			return 'fa-sort';
		};

		$scope.changeSorting = function(column) {
			if ($scope.sort.active === column) {
				$scope.sort.ascending = !$scope.sort.ascending;
			} else {
				$scope.sort.active = column;
				$scope.sort.ascending = false;
			}
			$scope.update();
		};

		$scope.changeSelection = function() {
			$scope.searchText = '';
			$scope.start = undefined;
			$scope.end = undefined;
			$scope.startDate = undefined;
			$scope.endDate = undefined;
			$scope.dates.$setPristine(); // hack
      		$scope.dates.$setValidity('startDate', true);
      		$scope.dates.$setValidity('endDate', true);
		};

		function buildQueryObject(pageNumber, sort) {
			return {
				searchType: $scope.searchType,
				orderBy: sort.active,
				orderAscending: sort.ascending,
				searchText: $scope.searchText,
				rowsPerPage: $scope.rowsPerPage,
				pageNumber: pageNumber,
				startDate: $scope.startDate,
				endDate: $scope.endDate,
				start: $scope.start,
				end: $scope.end
			}
		}

		function getDealOpen(pageNumber) {
			Deals.agreed(buildQueryObject(pageNumber, $scope.sort), function(data) {
				$scope.deals = data;
			});
		}

		function getDealDone(pageNumber) {
			Deals.done(buildQueryObject(pageNumber, $scope.sort), function(data) {
				$scope.deals = data;
			});
		}

		$scope.changeMode = function() {
			if ($scope.portfolio.mode) {
				$scope.$broadcast('buyer-portfolio-mode', { mode: $scope.portfolio.mode });
			}
		};

		$scope.showDeal = function(id) {
			$location.path('deal-view/' + id);
		};
	}
);