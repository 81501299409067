// User Factory
angular.module('inv.services')
.factory('User', function($resource) {
	var url = 'api/' + window.user.type + '/user/:userName';

	return $resource(url, { userName: '@userName' }, {
		sellers : { method: 'GET', isArray: true, url: 'api/admin/user/seller' },
		buyers : { method: 'GET', isArray: true, url: 'api/admin/user/buyer' },
		invoices: { method: 'GET',  isArray: true, url: 'api/admin/user/invoices/:userName' },
		getName: { method: 'GET',  isArray: false, url: 'api/admin/user/:userName/name' },
		deals: { method: 'GET',  isArray: true, url: 'api/admin/user/deals/:userId' },
		bids: { method: 'GET',  isArray: true, url: 'api/admin/user/bids/:userId' },
		verify: { method: 'POST', url: 'api/admin/user/:userName/verify' },
		suspend: { method: 'POST', url: 'api/admin/user/:userName/suspend' },
		vatExempt: { method: 'POST', url: 'api/admin/user/:userName/vatexempt' },
		resetPass: { method: 'POST', url: 'api/admin/user/:userName/resetpass' },
		setScores: { method: 'POST', url: 'api/admin/user/setscores' }
	});
});