// Number Directive
angular.module('inv.directives').directive('number', [
	'$filter',
	function($filter) {
		var link = function($scope, elm, attrs, ctrl) {
			var places = attrs.number || 3;

			ctrl.$parsers.push(function(val) {
				if(val === undefined) {
					ctrl.$setValidity('number', true); // empty is valid - required takes care of that
					return undefined;
				}

				var viewVal = val.replace(',', '');
				var test = $filter('number')(viewVal, places);
				if(!test) {
					ctrl.$setValidity('number', false);
					return undefined;
				}

				ctrl.$setValidity('number', true);
				return parseFloat(viewVal);
			});

			ctrl.$formatters.push(function(val) {
				if(val === undefined) {
					return undefined;
				}
				return $filter('number')(val, places);
			});

			elm.bind('blur', function(e) {
				if(ctrl.$modelValue) {
					ctrl.$viewValue = $filter('number')(ctrl.$modelValue, places);
					ctrl.$render();
				}
			});
		};

		return {
			restrict: 'A',
			require: 'ngModel',
			link: link
		}
	}
]);