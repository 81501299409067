angular.module('buyer.directives')
.directive('buyerPerformance', function () {
	return {
		templateUrl: 'app/js/buyer/directives/buyer-performance.html',
		restrict: 'E',
		scope: {
		},
		controller: function($scope, Chart) {
      $scope.data = [];
      $scope.opts = {
        chart: {
          type: 'lineChart',
          height: 400,
           margin : {
            top: 60,
            right: 60,
            bottom: 60,
            left: 65
          },
          x: function(d){return d[0];},
          y: function(d){return d[1] / 100;},
          useInteractiveGuideline: true,
          forceY: [1,12],
          color: d3.scale.category10().range(),
          yAxis: {
            tickFormat: function(d){
              return d3.format('.02f')(d);
            },
            showMaxMin: false
          },
          xAxis: {
            axisLabel: 'Week Ending',
            tickFormat: function(d) {
              return d3.time.format('%d/%m/%y')(new Date(d));
            }
          }
      	}
    	};

      Chart.performance({ start: '2016-01-01' }, function(data) {
        $scope.data = data;
      });
		}
	}
});