angular.module('inv.controllers')
.controller('BillingDetailsCtrl',
function ($scope, $location, $routeParams, Charges, User, Alerts, BankAccounts) {
	$scope.username = $routeParams.id;
	$scope.ct = 1;
	$scope.rowsPerPage = 10;
	//call just to bring back the name of the seller/buyer (refactory needed)
	$scope.user = User.get({ userName: $scope.username });
	$scope.bankAccounts = BankAccounts.query({ userName: $scope.username });

	$scope.invoices = Charges.invoices({ userName: $scope.username, page: $scope.ct, rows: $scope.rowsPerPage });
	$scope.outstanding = Charges.outstanding({ userName: $scope.username });

	$scope.fetchRows = function(ct) {
		$scope.ct = ct;
		$scope.invoices = Charges.invoices({ userName: $scope.username, page: ct, rows: $scope.rowsPerPage });
	};

	$scope.showInvoice = function(id) {
		$location.path('billing/' + $scope.username + '/invoice/' + id);
	}

	$scope.newFeeNote = function(id) {
		$location.path('billing/' + $scope.username + '/new');
	}

	$scope.editFeeNote = function(id) {
		$location.path("/billing/" + $scope.username + "/invoice/" + id + "/edit");
	}

	$scope.submitFeeNote = function(feeNote) {
		var message = Alerts.confirm({
			header: 'Submit Fee Note #' + feeNote.number,
			message: 'Are you sure you want to submit the Fee Note #' + feeNote.number
		});

		message.result.then(function() {
			Charges.send({ id: feeNote.id }, function() {
				feeNote.status = 2;
				feeNote.statusText = 'Unpaid';
			});
		});
	}

	$scope.markAsPaid = function(invoice) {
		Charges.markAsPaid({id: invoice.id}, function() {
			invoice.status = 3;
			invoice.statusText = 'Paid';
		});
	}

	$scope.verifyBankAccount = function(account) {
		BankAccounts.verify({ id: account.id }, function() {
			$scope.bankAccounts = BankAccounts.query({ userName: $scope.username });
		});
	}
});