// password strength
angular.module('inv.directives').directive('passStrength', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) { return; }

            //watch own value and re-validate on change
            scope.$watch(attrs.ngModel, function () {
                validate();
            });

            var validate = function () {
                ngModel.$parsers.unshift(function (viewValue) {
                    var pwdValidLength, pwdHasUppercase, pwdHasLowercase, pwdHasSpecial;

                    pwdValidLength = (viewValue && viewValue.length >= 7 ? true : false);
                    pwdHasUppercase = (viewValue && /[A-Z]/.test(viewValue)) ? true : false;
                    pwdHasLowercase = (viewValue && /[a-z]/.test(viewValue)) ? true : false;
                    pwdHasSpecial = (viewValue && /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(viewValue)) ? true : false;
                    //pwdHasNumber = (viewValue && /\d/.test(viewValue)) ? true : false;

                    if (pwdValidLength && pwdHasUppercase && pwdHasLowercase && pwdHasSpecial) {
                        ngModel.$setValidity('passStrength', true);
                    } else {
                        ngModel.$setValidity('passStrength', false);
                    }
                    return viewValue;
                });
            };
        }
    };
});