angular.module('inv.controllers')
.controller('FindDebtorController',
	function($scope, $modalInstance, $http, debtor) {
		$scope.debtor = debtor;

		$scope.cancel = function() {
			$modalInstance.dismiss('cancel');
			$scope.debtor.name = undefined;
			$scope.ran = false;
		};

		$scope.search = function() {
			if(!$scope.debtor.name) {
				$scope.ran = true;
				$scope.debtors = [];
				return;
			}

			$scope.debtors = null;
			$scope.loading = true;
			var url = "/api/register/checkcompanyname/" + $scope.debtor.name + "/" + $scope.debtor.country;
			$http.get(url)
				.success(function (data) {
					$scope.loading = false;
					$scope.debtors = data;
					$scope.ran = true;
				})
				.error(function (err) {
					$scope.loading = false;
					$scope.ran = true;
				});
		};

		$scope.selectDebtor = function(deb) {
			$modalInstance.close(deb);
			$scope.ran = false;
		};

		// do a search
		if($scope.debtor.name && $scope.debtor.name.length > 0)  {
			$scope.search();
		}
	}
);