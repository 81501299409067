angular.module('admin.controllers')
.controller('UsersCtrl',
	function($scope, User) {
		$scope.buyers = User.buyers();
		$scope.sellers = User.sellers();

		$scope.buyerSort = {
			active: ['verified', 'name'],
			descending: undefined
		};
		$scope.sellerSort = {
			active: ['verified', 'name'],
			descending: undefined
		};

		$scope.buyerQuery = "";
		$scope.sellerQuery = "";

		$scope.getIcon = function(column, type) {
			var sort = type === 'buyer' ? $scope.buyerSort : $scope.sellerSort ;
			if (sort.active === column) {
				return sort.descending
				? 'fa-chevron-up'
				: 'fa-chevron-down';
			}

			return 'fa-sort';
		};

		$scope.changeSorting = function(column, type) {
			var sort = type === 'buyer' ? $scope.buyerSort : $scope.sellerSort ;
			if (sort.active === column) {
				sort.descending = !sort.descending;
			} else {
				sort.active = column;
				sort.descending = false;
			}
		};
	}
);