angular.module('inv.controllers')
.controller('MyAccountCtrl',
function ($scope, $http, $location, Alerts, Profile, Charges, BankAccounts, EmailType) {
	$scope.edit = false;
	$scope.editPass = false;
	$scope.ct = 1;
	$scope.rowsPerPage = 10;

	EmailType.notificationSettings(function(data) {
		$scope.notificationsSettings = data;
	});

	$scope.switchEmailNotifications = function() {
		$scope.notificationsSettings.emailNotificationsEnabled = !$scope.notificationsSettings.emailNotificationsEnabled;
		EmailType.emailNotifications();
	}

	$scope.switchMarketingEmailNotifications = function() {
		$scope.notificationsSettings.marketingEmailNotificationsEnabled = !$scope.notificationsSettings.marketingEmailNotificationsEnabled;
		EmailType.marketingEmailNotifications();
	}

	$scope.getEmails = function() {
		EmailType.get(function(data) {
			$scope.emailTypes = data.emails;
		});
	};

	$scope.deleteEmail = function(id) {
		EmailType.delete({ id: id }, function(data) {
			$scope.getEmails();
		});
	};

	$scope.editEmail = function(e) {
		$scope.cancelAllEmailEdits();
		e.edit = !e.edit;
		e.oldEmail = angular.copy(e.email);
	};

	$scope.cancelAllEmailEdits = function() {
		$scope.newEmail = null;
		angular.forEach($scope.emailTypes, function(t) {
			angular.forEach(t.userEmails, function(e) {
				$scope.cancelEmail(e);
			});
		});
	};

	$scope.cancelEmail = function(e) {
		if(e.edit) {
			e.edit = !e.edit;
			e.email = angular.copy(e.oldEmail);
		}
	};

	$scope.cancelNewEmail = function() {
		$scope.newEmail = null;
	};

	$scope.addEmail = function(emailTypeId) {
		$scope.cancelAllEmailEdits();
		$scope.newEmail = { emailTypeId: emailTypeId };
	};

	$scope.updateEmail = function(email) {
		$scope.saveEmail(email);
	};

	$scope.saveNewEmail = function() {
		$scope.saveEmail($scope.newEmail);
	};

	$scope.saveEmail = function(email) {
		EmailType.update(email, function(data) {
			if(!data.success) {
				Alerts.error({
					header: "Cannot save email",
					message: data.message
				});
				return;
			}
			$scope.cancelAllEmailEdits();
			$scope.getEmails();
		});
	}

	$scope.fetchRows = function(ct) {
		$scope.ct = ct;
		$scope.invoices = Charges.invoices({ page: ct, rows: $scope.rowsPerPage });
	};

	$scope.invoices = Charges.invoices({ page: $scope.ct, rows: $scope.rowsPerPage }, function() {
		Charges.markAsSeen();
	});
	$scope.isSeller = window.user.type === 'seller';

	$scope.bankAccounts = function() {
		$location.path('account/bank-accounts');
	};

	$scope.getBankAccounts = function() {
		if($scope.isSeller) {
			$scope.bankAccount = BankAccounts.query(function() {
				$scope.oldBankAccount = angular.copy($scope.bankAccount);
			});
		}
	};
	$scope.getBankAccounts();

	$scope.rules = function(id) {
		$location.path('rules/');
	};

	$scope.showInvoice = function(id) {
		$location.path('billing/'+id);
	};

	function updateProfile() {
		$scope.profile = Profile.get(function() {
			$scope.editUser = $scope.profile;
			$scope.oldUser = angular.copy($scope.editUser);
			$scope.getEmails();
		});
	};
	updateProfile();

	$scope.savePass = function() {
		$scope.editUser.$save({ forPass: true }, function(success) {
			$scope.profile = Profile.get();
			Alerts.success({ header: 'Password changed', message: 'Your password has been changed'});
			$scope.editPass = false;
		}, function(error) {
			Alerts.warning({ header: 'Password not changed', message: error.data.message });
		});
	};

	$scope.saveUser = function() {
		if (!$scope.frm.email.$valid || !$scope.frm.name.$valid) {
			Alerts.warning({
				header: 'Invalid form',
				message: "Please correct the form and try again"
			});
		} else if ($scope.editUser.email === $scope.oldUser.email) {
			$scope.save();
		} else {
			// todo : this should be part of a validation
			var url = "api/" + window.user.type + "/profile/checkemail/" + $scope.editUser.email;
			$http.get(url)
				.success(function(data) {
					if(data.invalid === true) {
						Alerts.warning({ header: 'Email taken', message: "This email is already in use" });
						$scope.editUser.email = angular.copy($scope.oldUser.email);
					} else {
						$scope.save();
					}
				})
				.error(function(err) {
					$scope.cancel();
				});
		}
	};

	$scope.save = function() {
		$scope.editUser.$save(function(success) {
			updateProfile();
			$scope.edit = !$scope.edit;
		}, function(error) {
			$scope.cancel();
		});
	}

	$scope.cancel = function() {
		$scope.edit = false;
		$scope.editUser = angular.copy($scope.oldUser);
	};

	$scope.saveBankAccount = function() {
		$scope.BankAccounts.$save(function(success) {
			$scope.getBankAccounts();
			$scope.editBankAccount = !$scope.editBankAccount;
		}, function(error) {
			$scope.cancelBankAccount();
		});
	}

	$scope.cancelBankAccount = function() {
		$scope.editBankAccount = false;
		$scope.bankAccount = angular.copy($scope.oldBankAccount);
	};
});