angular.module('buyer.controllers')
.controller('AuctionsCtrl', function($scope, Auction) {
	$scope.pageNo = 1;
	var rows = 10;

	Auction.get(function (data) {
		$scope.recent = data.recent;
		$scope.upcoming = data.upcoming;
		$scope.past = data.past;
		$scope.noPast = data.pastCount;
		$scope.pageCount = Math.ceil(data.pastCount / rows);
	});

	function getPast() {
		$scope.past = Auction.past({ page: $scope.pageNo, rows: rows});
	}

	$scope.back = function () {
		$scope.pageNo--;
		getPast();
	}

	$scope.next = function() {
		$scope.pageNo++;
		getPast();
	}
});