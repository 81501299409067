// Modal Controller
angular.module('inv.controllers')
.controller('ModalCtrl',
	function($scope, $modalInstance, options) {
		$scope.message = options.message;
		$scope.header = options.header;
		$scope.danger = options.danger || false;
		$scope.warning = options.warning || false;
		$scope.okButton = options.okMessage || "Ok";

		$scope.ok = function() {
			$modalInstance.close();
		};

		$scope.cancel = function() {
			$modalInstance.dismiss('cancel');
		};
	}
);