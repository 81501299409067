angular.module('admin.controllers')
.controller('InvoiceCtrl',
	function($scope, $routeParams, Invoice, Deals, Auction, Alerts, $http, $timeout, $location) {
		$scope.invoice = Invoice.get({ id: $routeParams.id }, function() {
			if($scope.invoice.auctionId !== null && $scope.invoice.auctionDate >= moment(new Date()).utc().format("YYYY-MM-DD HH:mm")) {
				$scope.selectedAuction = $scope.invoice.auctionId;
				$scope.reassigned = true;
			}
		});
		$scope.uploadText = "Upload";
		Auction.get(function(data) {
			$scope.auctions = data.editable;
		});

		//routes
		if($routeParams.username) { $scope.previous = "user"; $scope.param = $routeParams.username; }
		else if($routeParams.debtorId) { $scope.previous = "debtor"; $scope.param = $routeParams.debtorId; }
		else if($routeParams.auctionId) { $scope.previous = "auction"; $scope.param = $routeParams.auctionId; }
		else if($routeParams.auctionLiveId) { $scope.previous = "auction-live"; $scope.param = $routeParams.auctionLiveId; }
		else { $scope.previous = "dashboard"; }

		//invoice vs deal
		$scope.showBidHistory = function () {
			$location.path('invoice-view/' + $scope.invoice.id + '/bid-history');
		};

		$scope.verify = function () {
			if($scope.invoice.redactedDocuments.length === 0) {
				var message = Alerts.confirm({
					header: 'No redacted documents were uploaded',
					message: 'You are about to verify an invoice without redacting any documents, do you wish to proceed?'
				});
				message.result.then(function() {
					$scope.verifyInvoice();
				});
			} else {
				$scope.verifyInvoice();
			}

		};

		$scope.verifyInvoice = function () {
			$scope.invoice.$approve({ auctionid: $scope.selectedAuction }, function() {
				$scope.showBubble("Assigned to Auction", "Invoice was assigned to Auction #" + $scope.selectedAuction);
				$scope.invoice = Invoice.get({ id: $routeParams.id });
			});
		}

		$scope.reject = function () {
			$scope.invoice.$reject(function() {
				$scope.invoice.status = 8;
				$scope.invoice.statusText = "Rejected";
			});
		};

		$scope.deleteDocument = function(doc) {
			var url = '/api/invoice/deletedocument';

			$http.post(url, doc)
				.success(function (result) {
					var index = $scope.invoice.redactedDocuments.indexOf(doc);
					$scope.invoice.redactedDocuments.splice(index, 1);
				});
		};

		$scope.showBubble = function(title, message) {
			$scope.growl = { title: title, message: message };
			$scope.updated = true;
			$timeout(function() {
				$scope.updated = false;
			}, 4000);
		};

		$scope.uploadFile = function(files){
			var fd = new FormData();
			fd.append("file", files[0]);
			$scope.fileData = fd;
			$scope.file = files[0];

			$scope.$apply();
		};

		$scope.upload = function () {
			if ($scope.file === undefined) {
				Alerts.warning({
					header: "No file selected",
					message: "Please select a document to upload."
				});
			} else if($scope.documentType === undefined) {
				Alerts.warning({
					header: "No type selected",
					message: "Please select a document type."
				});
			} else {
				$scope.uploadText = "Uploading...";
				var url = '/api/invoice/' + $scope.invoice.id + '/upload?type=' + $scope.documentType + '&redacted=true';

				$http.post(url, $scope.fileData, {
					headers: { 'Content-Type': undefined },
					transformRequest: angular.identity
				}).success(function(result) {
					document.getElementById("documentType").selectedIndex = 0;
					$scope.uploadText = "Upload";
					$scope.file = undefined;
					$scope.invoice.redactedDocuments = [];
					angular.forEach(result.documents, function(document) {
						if(document.redacted === true) {
							$scope.invoice.redactedDocuments.push(document);
						}
					});
					$scope.uploadSuccess = true;
					$timeout(function() {
						$scope.uploadSuccess = null;
					}, 2000);
				}).error(function(err) {
					$scope.uploadText = "Upload";
					$scope.file = undefined;
				});
				document.getElementById('redacted-input').value = null;
			}
		};
	}
);