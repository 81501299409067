angular.module('buyer.controllers')
.controller('HomeCtrl',
function ($scope, $location, $http, Dashboard, Bid, Profile, Charges, Deals, Position) {
	$scope.refresh = function() {
		$scope.auction = Dashboard.auctions(function(data) {
			$scope.updated = moment().format();
			timeZone(moment().format());
			// if auction - get pending balances
		});
	};

	function timeZone(data) {
		data = data.substr(data.length - 6);
		var pre = data.substr(0, 3);
		var suf = data.substr(3);
		var timeDiff = data.substr(1, 1) === '0' ? data.substr(2, 1) : data.substr(1, 2);
		suf === ':00' ? '' : timeDiff += suf;
		timeDiff = timeDiff === '0' ? '' : ' ' + data.substr(0, 1) + timeDiff; 
		$scope.timeZone = '(UTC' + timeDiff + ')';
	}

	Position.get(function(data) {
		$scope.position = data;
	});

	Position.pendingBalances(function(data) {
		$scope.pendingBalances = data.balances;
	});

	Profile.get(function(data) {
		$scope.passDaysLeft = data.passwordDaysLeft;
	});

	Charges.newInvoices(function(data) {
		$scope.newInvoices = data.newFeeNotes;
	});

	$scope.refresh();

	$scope.showBid = function(id) {
		$location.path('bid/' + id);
	};

	$scope.goToAccount = function() {
		$location.path('account');
	};
});