angular.module('inv.services')
.factory('Deals',
	function($resource) {
		var url = 'api/' + window.user.type + '/deal/:id';
		if(window.user.type === 'buyer') {
			return $resource(url, { id: '@id'}, {
				dealStats : { method: 'GET', url : 'api/buyer/deal/dealstats', isArray: false },
				agreed : { method: 'GET', url : 'api/buyer/deal/agreed', isArray: true,
					params: {
						searchType: '@searchType',
						orderBy: '@orderBy',
						orderAscending: '@orderAscending',
						searchText: '@searchText',
						rowsPerPage: '@rowsPerPage',
						pageNumber: '@pageNumber',
						startDate: '@startDate',
						endDate: '@endDate'
					}
				},
				done : { method: 'GET', url : 'api/buyer/deal/done', isArray: true,
					params: {
						searchType: '@searchType',
						orderBy: '@orderBy',
						orderAscending: '@orderAscending',
						searchText: '@searchText',
						rowsPerPage: '@rowsPerPage',
						pageNumber: '@pageNumber',
						startDate: '@startDate',
						endDate: '@endDate'
					}
				},
			});
		} else {
			return $resource(url, { id: '@id'}, {
				failed : { method: 'GET', url : 'api/seller/deal/failed', isArray: true },
				agreed : { method: 'GET', url : 'api/' + window.user.type + '/deal/agreed/:rows/:page', isArray: true },
				done : { method: 'GET', url : 'api/' + window.user.type + '/deal/done/:rows/:page', isArray: true },
				markAsPaid : { method: 'POST', url : 'api/admin/deal/:id/markaspaid', isArray: false }
			});
		}
	}
);