angular.module('buyer.directives')
.directive('buyerPortfolio', function () {
	return {
		templateUrl: 'app/js/buyer/directives/buyer-portfolio.html',
		restrict: 'E',
		scope: {
			type: '@',
			mode: '@'
		},
		controller: function($scope, Chart, $interval) {
			$scope.data = [];
			$scope.pieOpts = {
				chart: {
					type: 'pieChart',
					height: 200,
					margin: {
						top: 0,
						bottom: 0,
						left: 0,
						right: 0
					},
					legend: {
						margin: {
							top: 10
						}
					},
					x: function(d){ return d.name; },
					y: function(d){ return $scope.mode === '1' ? d.debitAdjusted : d.unrealisedGains; },
					showLabels: false,
					labelThreshold: 0.01,
					legendPosition: 'right'
				}
			};

			if($scope.type === 'seller') {
				$scope.data = Chart.sellerPortfolio();
			} else {
				$scope.data = Chart.debtorPortfolio();
			}

			$scope.$on('buyer-portfolio-mode', function(e, args) {
				if(args.mode !== $scope.mode) {
					$scope.mode = args.mode;

					var tempData = angular.copy($scope.data.reverse());

					$interval(function () {
						$scope.data = [];
						while((a = tempData.pop()) != null){
							$scope.data.push(a);
						}
					}, 1, true);
				}
			});
		}
	}
});