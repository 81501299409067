angular.module('inv.directives').directive('tableMessage', [
function() {
	return {
		template: '<div class="table-message" ng-hide="arr && arr.length > 0">{{ message }}</div>',
		restrict: 'E',
		scope: {
			arr: '=',
			message: '@'
		}
	}
}]);