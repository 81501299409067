angular.module('sellerApp')
.config(function($routeProvider) {
	$routeProvider
		.when('/rules', {
			controller: 'RulesCtrl', templateUrl: 'scripts/shared/rules-page.html' })
		.when('/statements', {
			controller: 'StatementsCtrl', templateUrl: 'scripts/seller/statements.html' })
		.when('/invoices', {
			controller: 'InvoicesCtrl', templateUrl: 'scripts/seller/invoices.html' })
		.when('/debtors', {
			controller: 'DebtorsCtrl', templateUrl: 'scripts/seller/debtors.html' })
		.when('/debtor/:id?', {
			controller: 'DebtorCtrl', templateUrl: 'scripts/seller/debtor.html' })
		.when('/deals', {
			controller: 'DealsCtrl', templateUrl: 'scripts/seller/deals.html' })
		.when('/account', {
			controller: 'MyAccountCtrl', templateUrl: 'scripts/shared/myAccount.html' })
		.when('/billing/:id', {
			controller: 'BillingCtrl', templateUrl: 'scripts/shared/billing.html' })
		.when('/invoice/:id?', {
			controller: 'InvoiceCtrl', templateUrl: 'scripts/seller/invoice.html' })
		.when('/invoice-view/:id', {
			controller: 'ViewInvoiceCtrl', templateUrl: 'scripts/seller/view-invoice.html' })
		.when('/deal-view/:id', {
			controller: 'DealCtrl', templateUrl: 'scripts/seller/deal.html' })
		.when('/invoice-doc/:id/:type/:doc', {
			controller: 'ViewInvoiceDocCtrl', templateUrl: 'scripts/shared/view-invoice-doc.html' })
		.when('/deal-view/:deal/bid-history', {
			controller: 'BidHistoryCtrl', templateUrl: 'scripts/shared/bidHistory.html' })
		.when('/invoice-view/:inv/bid-history', {
			controller: 'BidHistoryCtrl', templateUrl: 'scripts/shared/bidHistory.html' })
		.when('/account/bank-accounts', {
			controller: 'BankAccountsCtrl', templateUrl: 'scripts/shared/bankAccounts.html' })
		.when('/account/bank-accounts/new-bank-account', {
			controller: 'BankAccountCtrl', templateUrl: 'scripts/shared/bankAccount.html' })
		.when('/account/bank-accounts/edit-bank-account/:id', {
			controller: 'BankAccountCtrl', templateUrl: 'scripts/shared/bankAccount.html' })
		.when('/rates', { templateUrl: 'scripts/seller/rates.html' })
		.when('/', {
			controller: 'HomeCtrl', templateUrl: 'scripts/seller/home.html' })
		.otherwise({ redirectTo: '/'});
});