angular.module('seller.controllers')
.controller('DebtorCtrl',
	function($scope, $routeParams, $location, $http, $modal, Nationality, Alerts, Debtor) {
		$scope.focusInput = true;
		$scope.nationalities = Nationality.query();
		$scope.debtor = new Debtor();
		$scope.radioCompanyNumber = true;

		if($routeParams.id) {
			Debtor.get({ id: $routeParams.id }, function (data){
				$scope.debtor = data;
				$scope.debtor.ct = { name: data.country, code3: data.countryCode };

				if($scope.debtor.number == null || $scope.debtor.number === 'N/A' || $scope.debtor.number === 'NA') {
					$scope.radioCompanyNumber = false;
				}
			});
			$scope.isEdit = true;
		}

		$scope.countryFilter = function(item, viewValue) {
			var lowerStr = (item + "").toLowerCase();
			return lowerStr.indexOf(viewValue.toLowerCase()) === 0;
		};

		var backToDebtors = function() {
			$location.path('debtors');
		};

		$scope.cancel = function() {
			backToDebtors();
		};

		$scope.save = function() {
			if($scope.radioCompanyNumber && !validateCompanyNumber()) return;

			if($scope.debtorForm.$valid === false) {
				Alerts.warning({
					header: 'Validation Failed',
					message: 'Fill in all required field'
				});
				return false;
			}
			$scope.debtor.country = $scope.debtor.ct.name;
			$scope.debtor.countryCode = $scope.debtor.ct.code3;
			
			if(!$scope.radioCompanyNumber) $scope.debtor.number = null;
			
			$scope.debtor.$save(function() {
				if($scope.debtorContract) {
					uploadContract();
				}
				else { // no need to upload contract
					backToDebtors();
				}
			});
		};

		$scope.searchDebtor = function() {
			var modal = $modal.open({
				templateUrl: '/partials/modals/findDebtor.html',
				controller: 'FindDebtorController',
				resolve: {
					debtor: function() {
						return $scope.debtor;
					}
				}
			});

			modal.result.then(function(result) {
				$scope.debtor.number = result.number;
			});
		};

		var uploadContract = function() {
			var url = '/api/seller/debtor/uploadcontract/' + $scope.debtor.id;

			$http.post(url, $scope.debtorContract, {
				headers: { 'Content-Type': undefined },
				transformRequest: angular.identity
			}).success(function() {
				backToDebtors();
			});
		};

		$scope.applyFileToScope = function(files) {
			var fd = new FormData();
			fd.append("file", files[0]);
			$scope.debtorContract = fd;
			$scope.$apply();
		};

		$scope.onBlur = function() {
			validateCompanyNumber();
		};
	
		function validateCompanyNumber() {
			if($scope.debtor.number != null && $scope.debtor.number.toString().indexOf('/') !== -1) {
				Alerts.warning({
					header: 'Validation Failed',
					message: 'Invalid company number'
				});
	
				return false;
			}
	
			return true;
		}
	}
);