angular.module('inv.services')
.factory('Debtor', function($resource) {
	if(window.user.type === 'admin') {
		return $resource('api/admin/debtor/:id', { id: '@id' }, {
			approve : { method : 'POST', url: 'api/admin/debtor/:id/approve' },
			setScores: { method: 'POST', url: 'api/admin/debtor/:id/setscores' },
			setYield: { method: 'POST', url: 'api/admin/debtor/:id/yield/:yield', params: { id: '@id', yield: '@yield' } }
		});
	}
	if(window.user.type === 'seller') {
		return $resource('api/seller/debtor/:id', { id: '@id' });
	}
});