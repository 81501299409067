angular.module('inv.directives')
.directive('bankAccount', function(BankAccounts, TwoFactor, $rootScope) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/bankAccount.html',
		scope: {
			accountId: '=',
			showHeader: '=',
			saveChange: '&',
			cancelChange: '&'
		},
		link: function($scope) {
			TwoFactor.twoFactorEnabled(function(data) {
				$scope.twoFactorEnabled = data.twoFactorEnabled;
			});

			if($scope.accountId) { // edit existing account
				BankAccounts.byId({ id: $scope.accountId }, function(data) {
					$scope.bankAccount = data;
					$rootScope.initialCurrency = angular.copy(data.currency);
				});
			} else { // new account
				$scope.bankAccount = new BankAccounts();
			}

			$scope.cancel = function() {
				$scope.cancelChange();
			};

			$scope.save = function() {
				if($scope.twoFactorEnabled) {
					TwoFactor.sendCode(function() {
						$scope.twoFactor = true;
					});
				} else {
					$scope.saveBankAccount();
				}
			};

			$scope.saveBankAccount = function() {
				$scope.bankAccount.twoFactorCode = $scope.code;
				$scope.bankAccount.$save(function () {
					$rootScope.initialCurrency = $scope.bankAccount.currency;
					$scope.saveChange();
				}, function (err) {
					$scope.twoFactor = false;
				});
			}
		}
	}
});