angular.module('inv.controllers')
.controller('FeeNoteCtrl',
function ($scope, $location, $routeParams, Charges, Alerts, User) {
	$scope.feeNote = { id: $routeParams.id, user: { userName: $routeParams.username, name: $routeParams.username }, feeNoteLines: [] };
	$scope.newFeeNote = ($scope.feeNote.id === undefined) ? true : false;
	$scope.discount = { userName: $routeParams.username, details: "Discount" };
	$scope.initialDiscount = angular.copy($scope.discount);

	if (!$routeParams.username) {
		$scope.url = { href: "/billing/", text: "Billing" };
	} else {
		$scope.url = { href: "/billing/" + $routeParams.username, text: "Billing Details" };
	}

	$scope.dateUpdated = function() {
		Charges.getFeeNoteNumber({ date: $scope.feeNote.date }, function(data) {
			$scope.feeNote.number = data.number;
		});
	};

	$scope.outstanding = Charges.outstanding({ userName: $routeParams.username });
	if(!$scope.newFeeNote) {
		Charges.billing({ id: $routeParams.id }, function(data) {
			$scope.feeNote.feeNoteLines = data.feeNoteLines;
			$scope.feeNote.date = data.feeNote.date;
			$scope.feeNote.currency = data.feeNote.currency;
			$scope.feeNote.number = data.feeNote.number;
			$scope.feeNote.terms = data.feeNote.terms;
			$scope.feeNote.user.name = data.feeNote.name;
			$scope.calculateTotals();
		});
	} else {
		User.getName({ userName: $routeParams.username }, function(data){
			$scope.feeNote.user.name = data.name;
		});
	}

	$scope.save = function(status) {
		$scope.feeNote.status = status;
		Charges.saveFeeNote($scope.feeNote, function() {
			$location.path("billing/" + $routeParams.username);
		});
	}

	$scope.delete = function() {
		var message = Alerts.confirm({
			header: 'Delete Fee Note #' + $scope.feeNote.number,
			message: 'Are you sure you want to delete the Fee Note #' + $scope.feeNote.number
		});

		message.result.then(function() {
			Charges.deleteFeeNote({ id: $routeParams.id }, function() {
				$location.path("billing/" + $routeParams.username);
			});
		});
	}

	$scope.cancel = function() {
		$location.path("billing/" + $routeParams.username);
	}

	$scope.removeFeeNoteLine = function(feeNoteLine, index) {
		feeNoteLine.selected = false;
		if($scope.newFeeNote) {
			$scope.outstanding.push(feeNoteLine);
			$scope.feeNote.feeNoteLines.splice(index,1);
			$scope.calculateTotals();
		} else {
			Charges.removeFeeNoteLine({ id: feeNoteLine.id }, function(){
				$scope.outstanding.push(feeNoteLine);
				$scope.feeNote.feeNoteLines.splice(index,1);
				$scope.calculateTotals();
			});
		}
	}

	$scope.deleteFeeNoteLine = function(feeNoteLine, index) {
		Charges.deleteFeeNoteLine({ id: feeNoteLine.id }, function(){
			$scope.outstanding.splice(index,1);
		});
	}

	$scope.calculateTotals = function() {
		$scope.netAmountTotal = 0;
		$scope.vatAmountTotal = 0;
		$scope.amountTotal = 0;
		for (var i = 0; i < $scope.feeNote.feeNoteLines.length; i++) {
			$scope.netAmountTotal += $scope.feeNote.feeNoteLines[i].netAmount;
			$scope.vatAmountTotal += $scope.feeNote.feeNoteLines[i].vatAmount;
			$scope.amountTotal += $scope.feeNote.feeNoteLines[i].amountTotal;
		}
	}

	$scope.addSelected = function() {
		for (var i = $scope.outstanding.length - 1; i >= 0; i--) {
			var o = $scope.outstanding[i];
			if(o.selected !== undefined && o.selected === true) {
				$scope.feeNote.feeNoteLines.push(o);
				var index = $scope.outstanding.indexOf(o);
				$scope.outstanding.splice(index,1);
			}
		}
		$scope.calculateTotals();
		if($scope.discount.selected) {
			Charges.discount($scope.discount, function(data) {
				$scope.feeNote.feeNoteLines.push(data.inserted);
				$scope.discount = angular.copy($scope.initialDiscount);
				$scope.calculateTotals();
			});

		}
	};
});