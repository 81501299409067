angular.module('seller.controllers')
.controller('DebtorsCtrl',
	function($scope, Debtor, Invoice, Alerts, $location) {
		var reload = function() {
			$scope.debtors = Debtor.query();
		};

		reload();

		$scope.addDebtor = function() {
			$location.path('debtor/');
		};

		$scope.deleteDebtor = function(debtor) {
			debtor.$delete(function() {
				reload();
			});
		}

		$scope.editDebtor = function(debtor) {
			$location.path('debtor/' + debtor.id);
		};
	}
);