angular.module('inv.directives')
.directive('paginationButtons', function($timeout) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/pagination.html',
		scope: {
			field: '=',
			rowsPerPage: '=',
			type: '@',
			updateRows: '&'
		},
		link: function($scope) {
			$scope.ct = 1;

			$scope.$on('reset-pagination-directive', function(e) {
				$scope.ct = 1;
			});

			$scope.next = function() {
				var lastItem = $scope.field[$scope.field.length-1];
				if(lastItem.rowNum < lastItem.totalRows) {
					$scope.ct++;
					$scope.updateRows({ ct: $scope.ct, type: $scope.type });
				}
			}

			$scope.back = function() {
				var lastItem = $scope.field[$scope.field.length-1];
				if(lastItem.rowNum > $scope.rowsPerPage) {
					$scope.ct--;
					$scope.updateRows({ ct: $scope.ct, type: $scope.type });
				}
			}

			$scope.isNextDisabled = function() {
				if($scope.field && $scope.field.length > 0) {
					var lastItem = $scope.field[$scope.field.length-1];
					return (lastItem.rowNum < lastItem.totalRows) ? false : true;
				}
				return true;
			}

			$scope.isBackDisabled = function() {
				if($scope.field && $scope.field.length > 0) {
					var lastItem = $scope.field[$scope.field.length-1];
					return (lastItem.rowNum > $scope.rowsPerPage) ? false : true;
				}
				return true;
			}
		}
	}
});