angular.module('admin.controllers')
.controller('DealCtrl',
	function($scope, $routeParams, Deals) {
		//routes
		if($routeParams.username) { $scope.previous = "user"; $scope.param = $routeParams.username; }
		else { $scope.previous = "deals"; }

		$scope.deal = Deals.get({ id: $routeParams.id });
		$scope.markAsPaid = function(dealId) {
			Deals.markAsPaid({id: dealId}, function() {
				$scope.deal = Deals.get({ id: dealId });
			});
		}
	}
);