angular.module('inv.controllers')
.controller('ResetPassModalCtrl',
	function($scope, $modalInstance, Alerts, User, user, $http) {
		$scope.user = user;

		$scope.close = function () {
			$modalInstance.dismiss('cancel');
		}

		$scope.resetPassword = function () {
			$scope.user.$resetPass(function() {
				$modalInstance.close();
				Alerts.success({ header: 'Email submitted', message: 'The reset password link was emailed to the user '});
			}, function(err) {
				$modalInstance.close();
				//Alerts.warning({ header: 'Email not submitted', message: err.data.message });
			});
		}

		$scope.setPassword = function () {
			var user = { userName: $scope.user.userName, newPassword: $scope.password };
			$http.post('api/admin/user/setpass', user)
				.success(function(data) {
					$modalInstance.close();
					Alerts.success({ header: 'Password was set', message: 'The new password has been set successfully'});
				})
				.error(function(err) {
					$modalInstance.close();
					//Alerts.warning({ header: 'Password not set', message: err.data.message });
				});
		}
	}
);