angular.module('inv.services')
    .factory('Statement', function($resource) {
        return $resource('api/seller/statement', {}, {
            openDeals: {
                method: 'POST',
                url: 'api/seller/statement/open-deals/:username',
                responseType: 'arraybuffer',
                transformResponse: function(data) {
                    return {
                        response: new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    };
                }
            },
            feeReport: {
                method: 'POST',
                url: 'api/seller/statement/fee-report/:username',
                responseType: 'arraybuffer',
                transformResponse: function(data) {
                    return {
                        response: new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    };
                }
            },
            vatInvoice: {
                method: 'POST',
                url: 'api/seller/statement/vat-invoice/:id',
                responseType: 'arraybuffer',
                transformResponse: function(result) {
                    return {
                        response: new Blob([result], { type: "application/pdf" })
                    };
                }
            }
        });
    });