angular.module('seller.directives')
.directive('invoiceStep5', function($http, $filter, Calculator, Invoice) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			invoice: '='
		},
		templateUrl: '/js/seller/directives/invoice/invoice-step5.html',
		controller: function($scope) {
			var step = 5;

			$scope.receivableBlurred = function() {
				setReserve();
			};

			$scope.$on('invoice:step', function(ev, args) {
				if(args === step) {
					setReserve();
				}
			});

			$scope.$on('invoice:saving', function(ev, args) {
				if(args === step) {
					save();
				}
			});

			function setReserve() {
				if($scope.invoice.receivable) {
					Calculator.maxReserve({
						receivable: $scope.invoice.receivable,
						yield: $scope.invoice.debtor.yield ? $scope.invoice.debtor.yield : $scope.invoice.yield,
						daysRemaining: $scope.invoice.daysRemaining
					}, function(data) {
						$scope.invoice.recommendedReserve = data.maxReserve;
					});
				} else {
					$scope.invoice.recommendedReserve = null;
				}
			}

			function save() {
				$scope.$emit('invoice:valid');
			}
		}
	};
});