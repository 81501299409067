angular.module('buyer.controllers')
.controller('BidCtrl',
function ($scope, $routeParams, $timeout, $interval, $location, Bid, Alerts, Profile, Auction, Position) {
	$scope.user = window.user.username;
	$scope.remaining = null;
	$scope.showNewBid = false;
	$scope.displayAuctionMessage = false; // ng-cloak not working

	// join socket
	$timeout(function() {
		window.events.join($routeParams.id);
	}, 1000);

	Auction.current(function(data) {
		$scope.current = data.current;
		$scope.invoice.$promise.then(function(data) {
			if($scope.current && $scope.current.id === data.auctionId) {
				// count down timer
				var endDate = moment.utc($scope.current.auctionEndUtc).local();
				$scope.countdownTimer = countdown(
					onCountdown,
					new Date(endDate),
					countdown.HOURS|countdown.MINUTES|countdown.SECONDS
				);
			} else {
				$scope.remaining = null;
				$scope.displayAuctionMessage = true;
			}
		});
	});

	function onCountdown(time) {
		$scope.timer = time;
		$scope.remaining = (time.hours > 0) || (time.minutes > 0) || (time.seconds > 0);

		if(time.hours === 0 && time.minutes < 5) {
			$scope.timeDanger = true;
		}

		if(!$scope.remaining) {
			cancel();
			return;
		}

		if(!$scope.$$phase) { $scope.$apply(); }
	}

	function cancel() {
		$scope.remaining = null;
		$scope.displayAuctionMessage = true;
		if($scope.countdownTimer) {
			window.clearInterval($scope.countdownTimer);
		}

		if(!$scope.$$phase) { $scope.$apply(); }
	}

	// load and refresh invoice
	var refreshInvoices = function() {
		$scope.invoice = Bid.get({ id: $routeParams.id }, function() {
			$scope.history = $scope.invoice.bids.slice(0, 10);
			Position.indicativeBalance({ currency: $scope.invoice.currency }, function(data) {
				$scope.indicative = data.balance;
			});

			var free = $scope.invoice.allocationFree.percentage;
			var mine = $scope.invoice.allocationMine.percentage;
			var taken = $scope.invoice.allocationTaken.percentage;

			$scope.stacked = [];
			if(free > 0) {
				$scope.stacked.push({ value: free, type: 'info' });
			}
			if(mine > 0) {
				$scope.stacked.push({ value: mine, type: 'success' });
			}
			if(taken > 0) {
				$scope.stacked.push({ value: taken, type: 'danger' });
			}
		});
	};
	refreshInvoices();

	$scope.reload = function() {
		refreshInvoices();

		// show message when grid is refreshed
		$scope.showNewBid = true;
		$timeout(function() {
			$scope.showNewBid = false;
		}, 4000);
	};

	var succesfulBid = function(data) {
		Alerts.success({ header: 'Bid successful', message: 'The bid is successful and has been added'});
		window.events.bid($routeParams.id);
	};

	var errorBid = function(data) {
		Alerts.warning({ header: 'Bid not successful', message: data.message });
	};

	$scope.placeBid = function () {
		var alt = Alerts.showBid($scope.invoice);
		alt.result.then(function(bid) {
			Bid.placeBid({ invoiceId: $scope.invoice.id }, bid,
				function() { // success
					window.events.bid($routeParams.id);
				});
		});
	};

	$scope.showBidHistory = function () {
		$location.path('bid/' + $scope.invoice.id + '/bid-history');
	};

	$scope.showInvoice = function() {
		$location.path('invoice-view/' + $scope.invoice.id);
	};

	$scope.pageChanged = function() {
		var skip = ($scope.currentPage - 1) * 10;
		var to = skip + 10;
		$scope.history = $scope.invoice.bids.slice(skip, to);
	};

	$scope.viewProfile = function(filter, type) {
		var url = type === 'debtor' ? '/debtor-profile/' : '/seller-profile/';
		$location.path('bid/' + $scope.invoice.id + url + filter);
	};
});