angular.module('seller.directives')
.directive('invoiceStep3', function(BankAccounts, Alerts, $rootScope) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			invoice: '='
		},
		templateUrl: '/js/seller/directives/invoice/invoice-step3.html',
		controller: function($scope) {
			var step = 3;

			$scope.$on('invoice:step', function(ev, args) {
				if(args === step && !$scope.bankAccounts) {
					$scope.bankAccounts = BankAccounts.query(function() {
						$scope.invoice.bankAccount = $scope.invoice.bankAccountDetails;
					});
				}
			});

			$scope.$on('invoice:saving', function(ev, args) {
				if(args === step) {
					save();
				}
			});

			$scope.selectBankAccount = function(account) {
				$scope.invoice.bankAccount = account;
			}

			$scope.saveChange = function() {
				$scope.newAccount = false;
				$scope.bankAccounts = BankAccounts.query(function() {
					angular.forEach($scope.bankAccounts, function(acc) {
						if(acc.currency === $rootScope.initialCurrency) { $scope.invoice.bankAccount = acc; }
					});
				});
			};

			$scope.cancelChange = function() {
				$scope.newAccount = false;
			};

			function save() {
				if(!$scope.invoice.bankAccount) {
					$scope.$emit('invoice:invalid', 'Please select a Bank Account');
				}
				else {
					$scope.$emit('invoice:valid');
				}
			}
		}
	};
});