angular.module('inv.directives')
.directive('currencyField', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/inputs/currency-field.html',
		scope: {
			required: '@',
			label: '@',
			field: '=',
			name: '@'
		},
		controller: function($scope, Currency, $element) {
			$scope.currencies = Currency.query();
			$scope.blur = function(e) {
				var inputCurrency = e.target.value.toUpperCase();
				angular.forEach($scope.currencies, function(currency) {
					if (currency.code === inputCurrency) {
						$scope.field = currency.code;
						// kind of a hack
						var input = $element.find('input').controller('ngModel');
						input.$setValidity('editable', true);
					}
				});
			};
		}
	}
});