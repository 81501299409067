angular.module('seller.directives')
.directive('invoiceStep2', function(Debtor, Alerts) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			invoice: '='
		},
		templateUrl: '/js/seller/directives/invoice/invoice-step2.html',
		controller: function($scope) {
			var step = 2;

			$scope.$on('invoice:step', function(ev, args) {
				if(args === step && !$scope.debtors) {
					$scope.debtors = Debtor.query(function() {
						// debtor is stored on invoice.debtorDetails
						$scope.invoice.debtor = $scope.invoice.debtorDetails;
					});
				}
			});

			$scope.$on('invoice:saving', function(ev, args) {
				if(args === step) {
					save();
				}
			});

			$scope.selectDebtor = function(debtor) {
				$scope.invoice.debtor = debtor;
				if(debtor.hasContract === true) {
					$scope.invoice.requiresGrnOrPoUpload = false;
				} else {
					$scope.invoice.requiresGrnOrPoUpload = true;
				}
			}

			function save() {
				if(!$scope.invoice.debtor) {
					$scope.$emit('invoice:invalid', 'Please select a debtor');
				}
				else {
					$scope.$emit('invoice:valid');
				}
			}
		}
	};
});