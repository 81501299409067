angular.module('buyer.controllers')
.controller('TransactionCtrl', function($scope, $http, $routeParams) {
	$scope.index = 0;

	switch($routeParams.type) {
		case "withdrawals": {
			getWithdrawals();
			break;
		}
		case "gains": {
			getGains();
			break;
		}
		case "deployed": {
			getDeployed();
			break;
		}
		case "deposits":
		default: {
			getDeposits();
			break;
		}
	}

	$scope.getDeposits = getDeposits;
	$scope.getWithdrawals = getWithdrawals;
	$scope.getGains = getGains;
	$scope.getDeployed = getDeployed;

	function getWithdrawals() {
		$scope.index = 1;
		$http.get('/api/buyer/position/withdrawals')
			.then(function(res) {
				$scope.withdrawals = res.data;
			});
	}

	function getGains() {
		$scope.index = 2;
		$http.get('/api/buyer/position/gains')
			.then(function(res) {
				$scope.gains = res.data;
			});
	}

	function getDeposits() {
		$scope.index = 0;
		$http.get('/api/buyer/position/deposits')
			.then(function(res) {
				$scope.deposits = res.data;
			});
	}

	function getDeployed() {
		$scope.index = 3;
		$http.get('/api/buyer/position/deployed')
			.then(function(res) {
				$scope.deployed = res.data;
			});
	}

	function getData(endpoint) {
		return $http.get('/api/buyer/position/' + endpoint);
	}
});