angular.module('seller.directives')
.directive('invoiceStep1', function(Calculator) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			invoice: '='
		},
		templateUrl: '/js/seller/directives/invoice/invoice-step1.html',
		controller: function($scope) {
			var step = 1;
			$scope.maxDate = new Date();

			$scope.$on('invoice:loaded', function() {
				$scope.daysRemaining = $scope.invoice.daysRemaining;
			});

			$scope.$on('invoice:saving', function(ev, args) {
				if(args === step) {
					save();
				}
			});

			$scope.payUpdated = function() {
				$scope.daysRemaining = null;
				$scope.future = false;

				var payDate = moment($scope.invoice.payDate).format('YYYY-MM-DD');

				Calculator.daysToSettlement({ payDate: payDate }, function(data) {
					if (data.noDays < 14) {
						$scope.future = true;
						return;
					}
					$scope.daysRemaining = data.noDays;
				});
			};

			function save() {
				$scope.$emit('invoice:valid');
			}
		}
	};
});