// Charges Factory
angular.module('inv.services')
.factory('Charges', function($resource) {
	var url = 'api/' + window.user.type + '/charges/';
	if(window.user.type === 'admin') {
		return $resource(url, {}, {
			invoices : { method: 'GET', isArray: true, url: url+'feenotes/:userName/:rows/:page' },
			outstanding : { method: 'GET', isArray: true, url: url+'outstanding/:userName' },
			billing : { method: 'GET', isArray: false, url: url+'billing/:id' },
			discount : { method: 'POST', isArray: false, url: url+'discount' },
			markAsPaid : { method: 'POST', isArray: false, url: url+'feenote/:id/markaspaid', params: {id:'@id'} },
			send : { method: 'POST', isArray: false, url: url+'feenote/:id/send', params: {id:'@id'} },
			saveFeeNote : { method: 'POST', isArray: false, url: url+'feenote' },
			removeFeeNoteLine : { method: 'POST', isArray: false, url: url+'feenoteline/:id/remove', params: {id:'@id'} },
			deleteFeeNoteLine : { method: 'DELETE', isArray: false, url: url+'feenoteline/:id/delete' },
			deleteFeeNote : { method: 'DELETE', isArray: false, url: url+'feenote/:id/delete' },
			pdf : { method: 'GET', isArray: true, url: url+'feenote/:id/pdf' },
			getFeeNoteNumber : { method: 'GET', isArray: false, url: url+'feenote/number' }
		});
	} else {
		return $resource(url, {}, {
			invoices : { method: 'GET', isArray: true, url: url+'feenotes/:rows/:page' },
			outstanding : { method: 'GET', isArray: true, url: url+'outstanding' },
			billing : { method: 'GET', isArray: false, url: url+'billing/:id' },
			newInvoices : { method: 'GET', isArray: false, url: url+'newfeenotes' },
			markAsSeen : { method: 'POST', isArray: false, url: url+'markasseen' },
			pdf : { method: 'GET', isArray: true, url: url+'feenote/:id/pdf' }
		});
	}
});