// BankAccounts Factory
angular.module('inv.services')
.factory('BankAccounts', function($resource) {
	if(window.user.type === 'admin') {
		return $resource('api/admin/bankaccounts/:userName', { id: '@id' }, {
			verify : { method: 'POST', url: 'api/admin/bankaccounts/:id/verify' }
		});
	} else {
		return $resource('api/' + window.user.type + '/bankaccounts', { id: '@id' }, {
			byId: { method: 'GET', url: 'api/' + window.user.type + '/bankaccounts/:id', isArray: false },
			delete : { method: 'DELETE' }
		});
	}
});