angular.module('inv.controllers')
.controller('loginCtrl', function($scope, $modalInstance, $http, AuthService) {

	$scope.login = function() {
		AuthService
			.login($scope.userName, $scope.password)
			.then(function(response) {
				$modalInstance.dismiss();
			}, function(err) {
				AuthService.logout();
				window.location.href = '/logout';
			});
	};

	$scope.ping = function () {
		$http
			.get('/api/ping')
			.success(function () {
				$modalInstance.dismiss();
			});
	}
});