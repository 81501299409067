angular.module('admin.controllers')
.controller('AuctionCtrl',
	function($scope, $routeParams, $timeout, Invoice, Auction) {
		$scope.current = Auction.previous({ id: $routeParams.id });
		$scope.sendBuyerCampaign = function() {
			Auction.sendBuyerCampaign({ id: $routeParams.id }, function() {
				$scope.current.startEmailSent = true;
				$scope.showBubble("Emails sent", "Buyer Campaign emails were sent");
			});
		};

		//todo: create growl/bubble directive
		$scope.showBubble = function(title, message) {
			$scope.growl = { title: title, message: message };
			$scope.updated = true;
			$timeout(function() {
				$scope.updated = false;
			}, 4000);
		};
	}
);