angular.module('buyer.directives')
.directive('dealGrid', function () {
	return {
		templateUrl: 'app/js/buyer/directives/deal-grid.html',
		restrict: 'E',
		scope: {
			deals: '=',
			header: '@',
			showBidLink: '@',
			showAllocation: '@',
			showAnyway: '@',
			noDataMessage: '@'
		}
	}
})
