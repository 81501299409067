angular.module('seller.controllers')
.controller('InvoiceCtrl',
	function($scope, $routeParams, $location, $timeout, Invoice, Alerts, Debtor, BankAccounts) {
		$scope.step = 1;
		$scope.files = [];
		$scope.actionText = "New";
		$scope.invoice = new Invoice();

		if($routeParams.id) {
			$scope.invoice = Invoice.get({ id: $routeParams.id }, function() {
				$scope.$broadcast('invoice:loaded');
			});
			$scope.actionText = "Edit";
		}

		$scope.$watch('step', function(curr, prev) {
			if(curr) {
				$scope.$broadcast('invoice:step', curr);
			}
		});

		$scope.$on('field-selected', function(ev, args) {
			$scope.focus = args;
		});

		$scope.next = function() {
			$scope.$broadcast('invoice:saving', $scope.step);
		};

		$scope.prev = function() {
			$scope.step--;
			$scope.restoreDebtor($scope.invoice);
			$scope.restoreBankAccount($scope.invoice);
			//fetch uploaded documents (for changes)
			if($scope.step === 3 || $scope.step === 4) {
				Invoice.get({ id: $scope.invoice.id }, function (data){
					$scope.invoice.documents = data.documents;
					$scope.$broadcast('invoice:loaded');
				});
			}
		}

		$scope.saveForLater = function() {
			$scope.invoice.$save({ step: $scope.step, save: true }, function() {
				$scope.restoreDebtor($scope.invoice);
				$scope.restoreBankAccount($scope.invoice);
				Alerts.success({
					header: 'Invoice Saved',
					message: 'Invoice has been saved'
				});
			});
		};

		$scope.submit = function() {
			$scope.invoice.status = 1; // submitted

			$scope.invoice.$save({ step: $scope.step }, function() {
				Alerts.invoiceSuccess({ message: $scope.invoice.id });
			});
		};

		$scope.$on('invoice:invalid', function(ev, args) {
			Alerts.warning({
				header: 'Form Invalid',
				message: args
			});
		});

		$scope.$on('invoice:valid', function(ev) {
			$scope.invoice.$save({ step: $scope.step }, function(data) {
				$scope.invoice.yield = data.yield;
				$scope.restoreDebtor(data);
				$scope.restoreBankAccount(data);
				$scope.step++;
			});
		});

		$scope.restoreDebtor = function(data) {
			if($scope.step >= 2) {
				Debtor.get({ id: data.debtorDetails.id }, function(deb) {
					if(deb.hasContract === true) {
						$scope.invoice.requiresGrnOrPoUpload = false;
					} else {
						$scope.invoice.requiresGrnOrPoUpload = true;
					}
					$scope.invoice.debtorDetails = deb;
					$scope.invoice.debtor = deb;
				});
			}
		}

		$scope.restoreBankAccount = function(data) {
			if(($scope.step === 2 || $scope.step === 3) && data.bankAccountDetails.id) {
				BankAccounts.byId({ id: data.bankAccountDetails.id }, function(acc) {
					$scope.invoice.bankAccountDetails = acc;
					$scope.invoice.bankAccount = acc;
				});
			}
		}
	}
);