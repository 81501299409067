angular.module('loginApp')
	.controller('loginCtrl', function($scope, $location, $cookies, $http, AuthService) {

		$scope.login = function() {

			if($scope.loginForm.$valid) {
				$scope.message = '';

				AuthService
					.login($scope.userName, $scope.password)
					.then(function(response) {
						$http
							.post('/login', { UserName: $scope.userName, password: $scope.password })
							.success(function(data) {
								if(data.error) {
									$scope.message = data.error;
								} else if(data.redirect) {
									window.location.href = data.redirect;
								} else if(data.resetPass) {
									window.location.href = data.resetPass;
								} else if(data.twoFactor) {
									window.location.href = '/TwoFactor/Index';
								} else {
									window.location.href = '/';
								}
							});
					}, function(err) {
						$scope.message = err.error_description;
					});
			} else {
				$scope.message = 'Both fields are required';
			}
		};
	});