angular.module('inv.directives').directive('simplePopover', [
	'$window',
	'$compile',
function($window, $compile) {
	return {
		restrict: 'A',
		link: function(scope, element, attrs) {
			var popupWindow = angular.element($window.document.getElementById(attrs.simplePopover));
			var width = parseInt(attrs.simplePopoverWidth);
			var height = parseInt(attrs.simplePopoverHeight);
			var name = attrs.simplePopover;

			popupWindow.css({
				display: 'none',
				width: width + 'px',
				height: height + 'px'
			});

			var getPosition = function() {
				var elem = element[0];

				var windowTopDistance = elem.getBoundingClientRect().top;
				var creditScorePop = document.getElementById(name);

				var left = (elem.offsetLeft + elem.offsetWidth) - (width / 2) + 15;
				// show it at top
				var top = elem.offsetTop - height - 10;
				creditScorePop.className = "popover top";
				if(windowTopDistance - height - 10 < 0) {
					//show it at bottom
					top = elem.offsetTop + 20;
					creditScorePop.className = "popover bottom";
				}

				return {
					left: left,
					top: top
				}
			};

			element.on('mouseover', function(data) {
				var position = getPosition();

				popupWindow.css({
					// left:'61px',
					left: position.left + 'px',
					// top: '97px',
					top: position.top + 'px',
					display: 'block'
				})
			});

			element.on('mouseleave', function() {
				popupWindow.css({ display: 'none' });
			});
		}
	}
}]);