angular.module('seller.controllers')
    .controller('InvoicesCtrl',
        function($scope, $location, Invoice, Alerts) {
            $scope.invoices = Invoice.query();

            $scope.addInvoice = function() {
                $location.path('invoice/');
            };

            $scope.invoiceFilter = function(invoice) {
                if (!$scope.filter) {
                    return true;
                }
                var filter = parseInt($scope.filter);
                return invoice.status === filter;
            };

            $scope.selectInvoice = function(invoice) {
                if (invoice.status === 0) {
                    $location.path('invoice/' + invoice.id);
                } else {
                    $location.path('invoice-view/' + invoice.id);
                }
            };

            $scope.deleteInvoice = function(invoice) {
                var message = Alerts.confirm({
                    header: 'Delete Invoice',
                    message: 'Are you sure you want to delete invoice ' + invoice.id + "?"
                });

                message.result.then(function() {
                    invoice.$remove(function() {
                        $scope.invoices = Invoice.query();
                    }, function(err) {
                        Alerts.warning({
                            header: 'Error',
                            message: err.data.message
                        });
                    });
                });
            };
        }
    );