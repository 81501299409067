angular.module('inv.services').factory('TwoFactor',
	function($resource) {
		return $resource('api/' + window.user.type + '/twofactor', {}, {
			twoFactorEnabled: { method: 'GET', url: 'api/' + window.user.type + '/twofactor/enabled', isArray: false },
			getPhones: { method: 'GET', url: 'api/' + window.user.type + '/twofactor/phones', isArray: false },
			sendCode: { method: 'GET', url: 'api/' + window.user.type + '/twofactor/generate', isArray: false },
			resend: { method: 'GET', url: 'api/' + window.user.type + '/twofactor/resend', isArray: false },
			notReceived: { method: 'GET', url: 'api/' + window.user.type + '/twofactor/notreceived', isArray: false },
			verifyCode: { method: 'GET', url: 'api/' + window.user.type + '/twofactor/:code/verify', isArray: false }
		});
	}
);