angular.module('inv.directives')
.filter('statusColor', function () {
	return function (val) {
		//red = Not Submitted (0), Submitted for Review Review (1), Debtor Verified (2), Rejected (8), Failed to Sell (9)
		//blue = Invoice Verified (3), At Auction (4), Auction Complete (5)
		//green = Deal Agreed (6), Deal Done (7)
	    if ((val >= 0 && val <= 2) || val === 8 || val === 9 || val === 12) {
			return "status-label red-label";
	    }
	    else if (val >= 3 && val <= 5) {
			return "status-label blue-label";
	    }
	    return "status-label green-label";
	};
});