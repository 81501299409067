angular.module('inv.directives')
.directive('countryField', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/inputs/country-field.html',
		scope: {
			required: '@',
			ngRequired: '@',
			label: '@',
			secondlabel: '@',
			placeholder: '@',
			field: '=',
			submitted: '=',
			name: '@'
		},
		controller: function($scope, Nationality, $element) {
			$scope.nationalities = Nationality.query();
			$scope.blur = function(e) {
				var inputCountry = e.target.value.toUpperCase();
				angular.forEach($scope.nationalities, function(country) {
					if (country.name.toUpperCase() === inputCountry) {
						$scope.field = country;
						// kind of a hack
						var input = $element.find('input').controller('ngModel');
						input.$setValidity('editable', true);
					}
				});
			};
		}
	}
});