angular.module('inv.directives').directive('allocationBar', [
	function() {
		return {
			template: '<div class="allocation-bar">' +
							'<span class="info all-{{ round(invoice.allocationFree.percentage) }}">{{ invoice.allocationFree.percentage | number }}%</span>' +
							'<span class="danger all-{{ round(invoice.allocationTaken.percentage) }}">{{ invoice.allocationTaken.percentage | number }}%</span>' +
							'<span class="success all-{{ round(invoice.allocationMine.percentage) }}">{{ invoice.allocationMine.percentage | number }}%</span>' +
						'</div>',
			restrict: 'E',
			scope: {
				invoice: '='
			},
			link: function(scope, elem, attrs) {
				scope.round = function(amount) {
					return Math.round(amount);
				}
			}
		}
	}
]);