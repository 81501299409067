angular.module('seller.directives')
.directive('invoiceStep4', function($http, $q) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			invoice: '='
		},
		templateUrl: '/js/seller/directives/invoice/invoice-step4.html',
		controller: function($scope) {
			$scope.maxSize = 31457280;
			var step = 4;
			$scope.files = [];
			$scope.groupedFiles = [];
			$scope.groupedUploaded = [];

			$scope.$on('invoice:loaded', function() {
				if($scope.invoice.consolidated) {
					angular.forEach($scope.invoice.documents, function(doc) {
						if($scope.groupedUploaded.length === 0) {
							$scope.groupedUploaded.push({
								type: doc.type,
								fileName: doc.fileName,
								data: [{ name: doc.fileName }]
							});
						} else {
							var inserted = false;
							for (var i = 0; i < $scope.groupedUploaded.length; i++) {
								if($scope.groupedUploaded[i].type === doc.type) {
									$scope.groupedUploaded[i].data.push({ name: doc.fileName });
									inserted = true;
								}
							}
							if(inserted === false) {
								$scope.groupedUploaded.push({
									type: doc.type,
									fileName: doc.fileName,
									data: [{ name: doc.fileName }]
								});
							}
						}
					});
				}
			});

			$scope.additionalFileLoaded = function(file, data) {
				$scope.fileLoaded(file, data, $scope.documentType);
			};

			$scope.fileLoaded = function(file, data, type) {
				toggleRequiredDocs(type, true);

				$scope.files.push({
					file: file,
					data: data,
					type: type,
					fileName: file ? file.name : undefined
				});

				if($scope.groupedFiles.length === 0) {
					$scope.groupedFiles.push({
						type: type,
						size: file.size,
						fileName: file.name,
						data: [{ file: file, data: data, type: type }]
					});
				} else {
					var inserted = false;
					for (var i = 0; i < $scope.groupedFiles.length; i++) {
						if($scope.groupedFiles[i].type === type) {
							$scope.groupedFiles[i].size += file.size;
							$scope.groupedFiles[i].data.push({ file: file, data: data, type: type });
							inserted = true;
						}
					}
					if(inserted === false) {
						$scope.groupedFiles.push({
							type: type,
							size: file.size,
							fileName: file.name,
							data: [{ file: file, data: data, type: type }]
						});
					}
				}

				$scope.$apply();
			};

			$scope.deleteFromStorage = function(doc) {
				var url = '/api/invoice/deletedocument';
				$http
					.post(url, doc)
					.success(function (result) {
						var index = $scope.invoice.documents.indexOf(doc);
						$scope.invoice.documents.splice(index, 1);
					});
			};

			$scope.$on('invoice:saving', function(ev, args) {
				if(args === step) {
					save();
				}
			});

			function toggleRequiredDocs(type, toggleTo) {
				if(type === 'Invoice') {
					$scope.invoice.hasInvoice = toggleTo;
				}
				else if(type === 'GRN') {
					$scope.invoice.hasGrn = toggleTo;
				}
				else if (type === 'Purchase Order') {
					$scope.invoice.hasPurchaseOrder = toggleTo;
				}
			}

			function validateUploads() {
				if($scope.invoice.hasInvoice || $scope.invoice.hasGrn || $scope.invoice.hasPurchaseOrder) {
					return true;
				}

				return false;
			}

			$scope.deleteFile = function(type, fileName) {
				notify(type);

				for (var i = $scope.groupedFiles.length - 1; i >= 0; i--) {
					if ($scope.groupedFiles[i].type === type && $scope.groupedFiles[i].fileName === fileName) {
						$scope.groupedFiles.splice(i, 1);
					}
				}
				for (i = $scope.groupedUploaded.length - 1; i >= 0; i--) {
					if ($scope.groupedUploaded[i].type === type && $scope.groupedUploaded[i].fileName === fileName) {
						$scope.groupedUploaded.splice(i, 1);
					}
				}
				for (i = $scope.files.length - 1; i >= 0; i--) {
					if ($scope.files[i].type === type && $scope.files[i].fileName === fileName) {
						$scope.files.splice(i, 1);
					}
				}
				for (i = $scope.invoice.documents.length - 1; i >= 0; i--) {
					if ($scope.invoice.documents[i].type === type && $scope.invoice.documents[i].fileName === fileName) {
						$scope.deleteFromStorage($scope.invoice.documents[i]);
					}
				}
			}

			$scope.change = function(type) {
				$scope.deleteFileByType(type);
			}

			$scope.deleteFileByType = function (type) {
				notify(type);

				for (var i = $scope.groupedFiles.length - 1; i >= 0; i--) {
					if ($scope.groupedFiles[i].type === type) {
						$scope.groupedFiles.splice(i, 1);
					}
				}
				for (i = $scope.groupedUploaded.length - 1; i >= 0; i--) {
					if ($scope.groupedUploaded[i].type === type) {
						$scope.groupedUploaded.splice(i, 1);
					}
				}
				for (i = $scope.files.length - 1; i >= 0; i--) {
					if ($scope.files[i].type === type) {
						$scope.files.splice(i, 1);
					}
				}
				for (i = $scope.invoice.documents.length - 1; i >= 0; i--) {
					if ($scope.invoice.documents[i].type === type) {
						$scope.deleteFromStorage($scope.invoice.documents[i]);
					}
				}
			}

			function notify(type) {
				toggleRequiredDocs(type, false);
				$scope.$broadcast('file:changed', type);
				$scope.$broadcast('files:changed', type);
			}

			function checkFileType(files) {
				var ok = 0;
				angular.forEach(files, function(f) {
					var type = f.file.type;
					var allowed = ["pdf", "image"];
					for (var i = 0; i < allowed.length; i++) {
						if(type.indexOf(allowed[i]) > -1 && type.length !== 0) {
							ok++;
						}
					}
				});
				return ok !== files.length;
			}

			function save() {
				if(validateUploads() === false) {
					$scope.$emit('invoice:invalid', 'Please upload a document first');
					return false;
				}

				for (var i = 0; i < $scope.groupedFiles.length; i++) {
					if(checkFileType($scope.groupedFiles[i].data)) {
						var message = $scope.groupedFiles[i].data.length > 1 ? "all your documents are either images or PDF(s)" : "your document is either an image or a PDF";
						$scope.$emit('invoice:invalid', "Please make sure " + message);
						return false;
					}

					if($scope.groupedFiles[i].size > $scope.maxSize) {
						$scope.$emit('invoice:invalid',  $scope.invoice.consolidated ? 'Please make sure a group of files does not exceed 30MB' : 'Please make sure a file does not exceed 30MB');
						return false;
					}
				}

				if($scope.files.length === 0) {
					$scope.$emit('invoice:valid');
					return false;
				}

				var promises = []

				angular.forEach($scope.groupedFiles, function(f) {
					var url = '/api/invoice/' + $scope.invoice.id + '/upload?type=' + f.type + '&redacted=false';

					angular.forEach(f.data, function (d) {
						promises.push($http.post(url, d.data, {
							headers: { 'Content-Type': undefined },
							transformRequest: angular.identity
						}));
					});
				});

				$q.all(promises).then(function() {
					$scope.$emit('invoice:valid');
					$scope.files = [];
					$scope.groupedFiles = [];
					$scope.groupedUploaded = [];
				});
			}
		}
	};
});