angular.module('seller.controllers')
    .controller('StatementsCtrl',
        function($scope, $modal, Currency, Debtor, Statement, Invoice) {

            $scope.report = "open-deals";
            $scope.currencies = Currency.query();
            $scope.debtors = Debtor.query();

            $scope.statuses = [
                { name: "Open", value: "OPEN" },
                { name: "Late", value: "LATE" },
                { name: "Settled", value: "SETTLED" },
                { name: "Submitted", value: "NOT_SUBMITTED" }
            ]

            $scope.reportFilter = {};

            $scope.goTo = function(report) {
                for (var member in $scope.reportFilter) delete $scope.reportFilter[member];
                $scope.report = report;
            }

            $scope.searchInvoices = function() {
                $scope.invoices = Invoice.query();
            }

            $scope.selectDebtor = function(debtor) {
                $scope.reportFilter.debtors = null;
                $scope.reportFilter.debtor = debtor;
            }

            $scope.downloadVatInvoice = function(invoiceId) {
                $scope.reportFilter.invoiceId = invoiceId;
                $scope.downloadReport();
            }

            $scope.downloadReport = function() {
                if ($scope.reportFilter.debtor) {
                    $scope.reportFilter.debtors = [JSON.parse($scope.reportFilter.debtor).id];
                }

                if ($scope.reportFilter.currency) {
                    $scope.reportFilter.currencies = [$scope.reportFilter.currency];
                }

                if ($scope.reportFilter.selectedStatus) {
                    $scope.reportFilter.status = [$scope.reportFilter.selectedStatus];
                }

                var reader = new FileReader();

                reader.onload = function(e) {
                    window.open(decodeURIComponent(reader.result), '_self', '', false);
                }

                switch ($scope.report) {
                    case "open-deals":

                        if (!$scope.validOpenDeals()) {
                            return;
                        }

                        Statement.openDeals({ username: window.user.username }, $scope.reportFilter, function(response) {
                            var a = document.createElement("a");
                            var fileURL = window.URL.createObjectURL(response.response);
                            a.href = fileURL;
                            a.download = "OpenDeals.xlsx";
                            a.click();
                        });

                        break;
                    case "fee-report":

                        if (!$scope.validFeeReport()) {
                            return;
                        }

                        Statement.feeReport({ username: window.user.username }, $scope.reportFilter, function(response) {
                            var a = document.createElement("a");
                            var fileURL = window.URL.createObjectURL(response.response);
                            a.href = fileURL;
                            a.download = "Fee Report.xlsx";
                            a.click();
                        });
                        break;
                    case "vat-invoice":
                        if (!$scope.validVatInvoice()) {
                            return;
                        }

                        Statement.vatInvoice({ id: $scope.reportFilter.invoiceId }, null, function(response) {
                            var a = document.createElement("a");
                            var fileURL = window.URL.createObjectURL(response.response);
                            a.href = fileURL;
                            a.download = "#" + $scope.reportFilter.invoiceId + " VatInvoice.pdf";
                            a.click();
                        });

                        break;
                    default:
                        console.error("The selected report does not exist: ", $scope.report);
                        break;
                }
            }

            $scope.validFeeReport = function() {
                return true;
            }

            $scope.validVatInvoice = function() {
                return true;
            }

            $scope.validOpenDeals = function() {

                var valid = $scope.reportFilter.date != null;

                if (!valid) {
                    $modal.open({
                        templateUrl: '/partials/modals/message.html',
                        backdrop: 'static',
                        controller: 'ModalCtrl',
                        resolve: {
                            options: function() {
                                return {
                                    header: 'Error Encountered',
                                    message: 'Date can not be empty',
                                    danger: true
                                };
                            }
                        }
                    });
                }

                return valid;
            }

            $scope.invoiceFilter = function(invoice) {
                return ($scope.reportFilter.fromDate ? $scope.reportFilter.fromDate <= invoice.date : true) &&
                    ($scope.reportFilter.toDate ? $scope.reportFilter.toDate >= invoice.date : true) &&
                    (invoice.status == 6 || invoice.status == 7) &&
                    ($scope.reportFilter.debtor ? JSON.parse($scope.reportFilter.debtor).name === invoice.debtor : true) &&
                    ($scope.reportFilter.currency ? $scope.reportFilter.currency === invoice.currency : true);
            };


            $scope.isNewBankingInvoice = function(invoice) {
                return invoice.date >= "2019-12-59T23:59:99"
            };

            $scope.getReadableStatus = function(invoiceStatus) {
                var statuses = {
                    6: "Open",
                    7: "Settled"
                };

                return statuses[invoiceStatus] ? statuses[invoiceStatus] : "";
            }
        });