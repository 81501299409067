angular.module('inv.directives')
.directive('twoFactor', function(TwoFactor, AuthService) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/twoFactor.html',
		scope: {
			enabled: '=',
			code: '=',
			small: '=',
			header: '@',
			saveChange: '&'
		},
		link: function($scope) {
			TwoFactor.getPhones(function(data) {
				$scope.phones = data;
			});

			$scope.resendCode = function() {
				TwoFactor.resend(function() {
					$scope.invalidCode = false;
					$scope.resentCode = true;
				});
			};

			$scope.codeNotReceived = function() {
				TwoFactor.notReceived(function() {
					$scope.notReceivedCode = true;
					$scope.resentCode = false;
				});
			};

			$scope.resetFlags = function() {
				$scope.invalidCode = false;
				$scope.notReceivedCode = false;
				$scope.resentCode = false;
			};

			$scope.verifyCode = function() {
				$scope.resetFlags();
				TwoFactor.verifyCode({ code: $scope.code }, function (data) {
					if(data.valid) {
						$scope.saveChange();
					} else {
						$scope.invalidCode = true;
						if(data.suspended) {
							AuthService.logout();
							window.location.href = '/twofactor/suspended';
						}
					}
				});
			}
		}
	}
});