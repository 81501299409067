angular.module('inv.controllers')
.controller('BillingCtrl',
function ($scope, $routeParams, Charges, Alerts, $window, $location) {
	$scope.customerInvoiceId = $routeParams.id;

	Charges.pdf({ id: $scope.customerInvoiceId }, function(data) {
		$scope.downloadData = data[0];
	});

	if(window.user.type === 'admin') {
		$scope.isAdmin = true;
		$scope.username = $routeParams.username;
		if (!$routeParams.username) {
			$scope.url = { href: "/billing/", text: "Billing" };
		} else {
			$scope.url = { href: "/billing/" + $routeParams.username, text: "Billing Details" };
		}
	}

	$scope.download = function() {
		if($scope.downloadData !== undefined && $scope.downloadData.url !== undefined) {
			$window.open($scope.downloadData.url);
		} else {
			Alerts.warning({
				header: "No document available",
				message: "Download not available at this time, please try again later. If problem persist, please contact IT support."
			});
		}
	}

	$scope.markAsPaid = function() {
		Charges.markAsPaid({ id: $routeParams.id }, function() {
			$scope.invoice.status = 3;
		});
	}

	$scope.send = function() {
		var message = Alerts.confirm({
			header: 'Submit Fee Note #' + $scope.invoice.number,
			message: 'Are you sure you want to submit the Fee Note #' + $scope.invoice.number
		});

		message.result.then(function() {
			Charges.send({ id: $routeParams.id }, function() {
				$scope.invoice.status = 2;
			});
		});
	}

	$scope.edit = function() {
		var userName = ($routeParams.username !== undefined) ? $routeParams.username : $scope.invoice.userName;
		$location.path("/billing/" + userName + "/invoice/" + $scope.customerInvoiceId + "/edit");
	}

	$scope.delete = function() {
		var message = Alerts.confirm({
			header: 'Delete Fee Note #' + $scope.invoice.number,
			message: 'Are you sure you want to delete the Fee Note #' + $scope.invoice.number
		});

		message.result.then(function() {
			Charges.deleteFeeNote({ id: $routeParams.id }, function() {
				$location.path($scope.url.href);
			});
		});
	}

	Charges.billing({ id: $scope.customerInvoiceId }, function(data) {
		$scope.transactions = data.feeNoteLines;
		$scope.invoice = data.feeNote;
	});
});