angular.module('admin.controllers')
.controller('DebtorCtrl',
	function($scope, $routeParams, $location, $http, $timeout, $window, Debtor, Alerts, CompanyReport) {
		//routes
		if($routeParams.username) { $scope.previous = "user"; $scope.param = $routeParams.username; }
		else { $scope.previous = "debtors"; }

		$scope.uploadText = "Upload";
		$scope.getDebtor = function() {
			$scope.debtor = Debtor.get({ id: $routeParams.id }, function() {
				$scope.scores = {
					creditScore: $scope.debtor.creditScore,
					creditGuide: $scope.debtor.creditGuide
				};
				$scope.oldScores = angular.copy($scope.scores);
				$scope.yield = $scope.debtor.yield;
				$scope.oldYield = angular.copy($scope.yield);
			});
		};
		$scope.getDebtor();

		$scope.companyReport = function() {
			if($scope.debtor.companyReport) {
				$window.open($scope.debtor.companyReport.url);
			} else {
				Alerts.warning({
					header: 'No Report Available',
					message: "No Company Report available at the moment."
				});
			}
		};

		$scope.verifyDebtor = function(debtor) {
			if(!debtor.userVerified) {
				var message = Alerts.confirm({
					header: 'Seller not verified',
					message: "Seller has not been verified, do you wish to proceed?"
				});

				message.result.then(function() {
					$scope.finishVerify(debtor)
				});
			} else {
				$scope.finishVerify(debtor);
			}
		};

		$scope.finishVerify = function(debtor) {
			Debtor.approve({ id: debtor.id }, function() {
				debtor.status = 1;
				debtor.statusText = 'Verified';
			});
		};

		$scope.saveScores = function() {
			Debtor.setScores({ id: $scope.debtor.id },$scope.scores, function() {
				$scope.oldScores = angular.copy($scope.scores);
				$scope.editScores = !$scope.editScores;
			}, function() {
				$scope.cancelScores();
			});
		};

		$scope.cancelScores = function() {
			$scope.editScores = false;
			$scope.scores = angular.copy($scope.oldScores);
		};

		$scope.saveYield = function() {
			Debtor.setYield({ id: $scope.debtor.id, yield: $scope.yield }, function() {
				$scope.oldYield = angular.copy($scope.yield);
				$scope.editYield = !$scope.editYield;
			}, function() {
				$scope.cancelYield();
			});
		}

		$scope.cancelYield = function() {
			$scope.editYield = false;
			$scope.yield = angular.copy($scope.oldYield);
		};

		$scope.deleteDocument = function(doc) {
			doc.companyNumber = $scope.debtor.reportIdentifier;
			CompanyReport.remove(doc, function() {
				$scope.debtor.companyReport = null;
			});
		};

		$scope.uploadFile = function(files){
			var fd = new FormData();
			fd.append("file", files[0]);
			$scope.fileData = fd;
			$scope.file = files[0];

			$scope.$apply();
		};

		$scope.upload = function () {
			if ($scope.file === undefined) {
				Alerts.warning({
					header: "No file selected",
					message: "Please select a document to upload."
				});
			} else {
				$scope.uploadText = "Uploading...";
				var url = '/api/admin/companyreport/upload/' + $scope.debtor.reportIdentifier;

				$http.post(url, $scope.fileData, {
					headers: { 'Content-Type': undefined },
					transformRequest: angular.identity
				}).success(function(result) {
					$scope.uploadText = "Upload";
					$scope.file = undefined;
					document.getElementById('styled-finputs-example').value = null;
					$scope.getDebtor ();
					$scope.uploadSuccess = true;
					$timeout(function() {
						$scope.uploadSuccess = null;
					}, 2000);
				}).error(function(err) {
					$scope.uploadText = "Upload";
					$scope.file = undefined;
				});
			}
		};
	}
);