// todo : change all alert methods to the alerter service
(function() {
	function Alerter($modal) {
		this.baseHtml = '/partials/modals/';
		this.$modal = $modal;
	}

	Alerter.prototype.alert = function(modalName, options) {
		if(!modalName.endsWith('.html')) {
			modalName = modalName + '.html';
		}
		var opts = options || {};

		return this.$modal.open({
			templateUrl: this.baseHtml + modalName,
			size: opts.size,
			controller: opts.controller,
			resolve: opts.resolve
		});
	};

	angular.module('inv.services').service('Alerter', Alerter);
})();

angular.module('inv.services')
.factory('Alerts', function($modal) {
	var invalidForm = function(form) {
		$modal.open({
			templateUrl: '/partials/modals/invalid-form.html',
			size: 'sm'
		});
	};

	var success = function(options) {
		return $modal.open({
			templateUrl: 'partials/modals/message.html',
			controller: 'ModalCtrl',
			resolve: {
				options: function() {
					return options;
				}
			}
		});
	};

	var invoiceSuccess = function(options) {
		return $modal.open({
			templateUrl: 'partials/modals/invoice-message.html',
			controller: 'ModalCtrl',
			resolve: {
				options: function() {
					return options;
				}
			}
		});
	};

	var dealSuccess = function(options) {
		return $modal.open({
			templateUrl: 'partials/modals/deal-agreed.html',
			controller: 'ModalCtrl',
			resolve: {
				options: function() {
					return options;
				}
			}
		});
	};

	var warning = function(options) {
		options.warning = true;
		success(options);
	};

	var error = function(options) {
		options.danger = true;
		success(options);
	};

	var confirm = function(options) {
		return $modal.open({
			templateUrl: 'partials/modals/confirm-message.html',
			controller: 'ModalCtrl',
			resolve: {
				options: function() {
					return options;
				}
			}
		});
	};

	var showBid = function(invoice) {
		return $modal.open({
			templateUrl: 'partials/modals/bid.html',
			size: 'lg',
			controller: 'NewBidCtrl',
			resolve: {
				invoice: function() {
					return invoice;
				}
			}
		});
	};

	var resetPassword = function(user) {
		return $modal.open({
			templateUrl: 'partials/modals/resetPassword.html',
			size: 'sm',
			controller: 'ResetPassModalCtrl',
			resolve: {
				user: function() {
					return user;
				}
			}
		});
	};

	return {
		invalidForm: invalidForm,
		success: success,
		invoiceSuccess: invoiceSuccess,
		dealSuccess: dealSuccess,
		warning: warning,
		error: error,
		confirm: confirm,
		showBid: showBid,
		resetPassword: resetPassword
	};
});