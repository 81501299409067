angular.module('admin.controllers')
.controller('SearchCtrl',
	function($scope, $modal, $location, $http) {
		$scope.company = { number : "" };
		$scope.tab = 'overview';
		$scope.currentYear = (new Date()).getFullYear();
		$scope.ranReport = false;

		//reusing the "find debtor" modal & controller
		$scope.debtor = { company: true };
		$scope.searchCompany = function() {
			var modal = $modal.open({
				templateUrl: '/partials/modals/findDebtor.html',
				controller: 'FindDebtorController',
				resolve: {
					debtor: function() {
						return $scope.debtor;
					}
				}
			});

			modal.result.then(function(result) {
				$scope.company.number = result.number;
				$scope.ranReport = false;
				$scope.debtor.name = undefined;
			});
		};

		$scope.companyReport = function() {
			if($scope.company.number && $scope.company.number.length > 3) {
				$scope.runReport($scope.company.number);
			}
		};

		$scope.runReport = function(number) {
			if(!number) {
				return;
			}

			var req = {
				nationalNumber: number,
				functionNumber: "3"
			};
			$scope.searching = true;

			$http.post('/api/company', req).success(function(data) {
				$scope.searching = false;
				$scope.cc = data;
				$scope.companyExists = $scope.cc.companyInfoReport ? true : false;
				$scope.companyNumber = number;
				$scope.ranReport = true;
			});
		};

		$scope.companyDocument = function(fileNetId) {
			var url = '/api/company/document?fileNumber=' + fileNetId;
			url += '&companyNumber=' + $scope.user.companyInfo.companyNumber;
			$scope.searching = true;

			$http.post(url).success(function(data) {
				$scope.searching = false;
			});
		}
	}
);