angular.module('inv.directives')
.directive('dateField', function($timeout) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/inputs/date-field.html',
		scope: {
			required: '@',
			field: '=',
			invalid: '=',
			format: '@',
			label: '@',
			placeholder: '@',
			noFutureDate: '@',
			minify: '@',
			name: '@',
			updated: '&',
			dob: '='
		},
		link: function($scope) {
			$scope.placeholder = 'Enter date in format dd/mm/yyyy';
			if($scope.noFutureDate) {
				$scope.maxDate = new Date();
			}

			var holidays = [];
			$scope.getAllBankHolidays = function(year) {
				var newYearsEve = $scope.getNewYearsEve(year);
				var saintPatricksDay = $scope.getSaintPatricksDay(year);
				var christmasDay = $scope.getChristmasDay(year);
				var saintStephensDay = $scope.getSaintStephensDay(year);
				var firstMondayInMay = $scope.getFirstMondayInMay(year);
				var firstMondayInJune = $scope.getFirstMondayInJune(year);
				var firstMondayInAugust = $scope.getFirstMondayInAugust(year);
				var lastMondayInOctober = $scope.getLastMondayInOctober(year);
				var easterDay = $scope.getEasterDay(year);
				var goodFriday = $scope.getGoodFriday(year);
				holidays.push(newYearsEve);
				holidays.push(saintPatricksDay);
				holidays.push(christmasDay);
				holidays.push(saintStephensDay);
				holidays.push(firstMondayInMay);
				holidays.push(firstMondayInJune);
				holidays.push(firstMondayInAugust);
				holidays.push(lastMondayInOctober);
				holidays.push(easterDay);
				holidays.push(goodFriday);
			}

			$scope.disabled = function(date, mode) {
				if(!$scope.dob) {
					$scope.getAllBankHolidays(new Date(date).getFullYear());
					if(holidays.indexOf(moment(date).format("YYYY-MM-DD")) != -1) return true;
					if(date.getDay() == 0 || date.getDay() == 6) return true;
					return false;
				}
			}

			$scope.getNewYearsEve = function(year) {
				var date = new Date(year, 0, 1);
				date = $scope.isWeekend(date);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getSaintPatricksDay = function(year) {
				var date = new Date(year, 2, 17);
				date = $scope.isWeekend(date);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getChristmasDay = function(year) {
				var date = new Date(year, 11, 25);
				date = $scope.isWeekend(date);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getSaintStephensDay = function(year) {
				var date = new Date(year, 11, 26);
				switch(date.getDay()) {
					case 0:
					case 6:
						date.setDate(date.getDate() + 2);
						break;
					case 1:
						date.setDate(date.getDate() + 1);
						break;
				}
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getFirstMondayInMay = function(year) {
				var date = new Date(year, 4, 1);
				date = $scope.firstMondayInMonth(date);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getFirstMondayInJune = function(year) {
				var date = new Date(year, 5, 1);
				date = $scope.firstMondayInMonth(date);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getFirstMondayInAugust = function(year) {
				var date = new Date(year, 7, 1);
				date = $scope.firstMondayInMonth(date);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getLastMondayInOctober = function(year) {
				var date = new Date(year, 9, 31);
				while(date.getDay() != 1) {
					date.setDate(date.getDate() - 1);
				}
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getEasterSunday = function(year) {
				var f = Math.floor,
				G = year % 19,
				C = f(year / 100),
				H = (C - f((C/4)) - f((8*C + 13)/25) + 19*G + 15)%30,
				I = H - f(H/28) * (1 - f(H/28) * f(29/(H + 1)) * f((21 - G)/11)),
				day = I - ((year + f(year/4) + I + 2 - C + f(C/4))%7) + 28,
				month = 3;
				return new Date(year, month-1, day);
			}

			$scope.getEasterDay = function(year) {
				var date = $scope.getEasterSunday(year);
				date.setDate(date.getDate() + 1);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.getGoodFriday = function(year) {
				var date = $scope.getEasterSunday(year);
				date.setDate(date.getDate() - 2);
				date = moment(date).format("YYYY-MM-DD");
				return date;
			}

			$scope.isWeekend = function(date) {
				switch(date.getDay()) {
					case 0:
						date.setDate(date.getDate() + 1);
						break;
					case 6:
						date.setDate(date.getDate() + 2);
				}
				return date;
			}

			$scope.firstMondayInMonth = function(date) {
				while(date.getDay() != 1) {
					date.setDate(date.getDate() + 1);
				}
				return date;
			}

			$scope.update = function() {
				if(typeof($scope.field) === 'undefined') {
					return;
				}
				$scope.field = moment($scope.field).format("YYYY-MM-DD");
				if(holidays.indexOf($scope.field) != -1) {
					$scope.field = '';
				}
				$scope.updated($scope.field);
			};

			$scope.open = function(ev) {
				ev.preventDefault();
    			ev.stopPropagation();
    			$scope.opened = true;
			};
		}
	}
});