angular.module('adminApp')
.config(function($httpProvider, datepickerConfig, datepickerPopupConfig) {
	$httpProvider.interceptors.push('AuthInterceptor');
	$httpProvider.interceptors.push('AuthTimer');
	$httpProvider.interceptors.push('LoadingService');
	$httpProvider.interceptors.push('ErrorService');
	// configure date popup
	datepickerConfig.showWeeks = false;
	datepickerPopupConfig.toggleWeeksText = null;
	datepickerPopupConfig.datepickerPopup = "dd/MM/yyyy";
})
.run(function($rootScope, Alerter, $http) {
	$rootScope.showWarning = function () {
		Alerter.alert('login', {
			controller: 'loginCtrl'
		});
	}
});