// Auction Factory
angular.module('inv.services')
.factory('Auction',
	function($resource) {
		if(window.user.type === 'buyer') {
			return $resource('api/buyer/auction/:id', { id: '@id'}, {
				past: { method: 'GET', url: 'api/buyer/auction/past', isArray: true },
				bids: { method: 'GET', url: 'api/buyer/auction/bids', isArray: false },
				current: { method: 'GET', url: 'api/buyer/auction/current', isArray: false }
			});
		}

		return $resource('api/admin/auction/', { id: '@id' }, {
			previous : { method: 'GET', url : 'api/admin/auction/:id' },
			buyers : { method: 'GET', url : 'api/admin/auction/buyers', isArray: true },
			upcoming : { method: 'GET', url : 'api/admin/auction/upcoming', isArray: true },
			sendBuyerCampaign : { method: 'POST', url : 'api/admin/auction/sendcampaign/:id' },
			addAuction : { method: 'POST', url : 'api/admin/auction/add' },
			updateAuction : { method: 'POST', url : 'api/admin/auction/update' },
			closeAuction : { method: 'POST', url : 'api/admin/auction/close' }
		});
	}
);