// Date Control Directive
angular.module('inv.directives')
	.directive('date', [function () {
	return {
		templateUrl: '/scripts/shared/dateControl.html',
		restrict: 'E',
		replace: true,
		require: '?ngModel',
		scope: {
			day: '=day',
			month: '=month',
			year: '=year',
			date: '=date',
			invalid: '=invalid',
			customize: '=customize',
			maxDate: '=maxdate',
			hideButtons: '=hideButtons'
		},
		transclude: true,
		link: function (scope, element, attrs, ctrl) {
			scope.$watch('date', function (newValue, oldValue) {
				if (newValue) {
					if (scope.maxDate) {
						if (newValue > scope.maxDate) {
							//scope.date = scope.maxDate;
							scope.invalid = true;
							return;
						}
					}

					var date = moment(newValue).format("L").split("/");
					scope.day = date[1];
					scope.month = date[0];
					scope.year = date[2];
				}
			});

			scope.$watch('day', function (newValue, oldValue) {
				if(newValue && newValue.length > 2 && newValue.substring(0,1) === "0") {
					scope.day = scope.day.substring(1,scope.day.length);
				}
				scope.checkDateFields(scope.day, scope.month, scope.year);
			});

			scope.$watch('month', function (newValue, oldValue) {
				if(newValue && newValue.length > 2 && newValue.substring(0,1) === "0") {
					scope.month = scope.month.substring(1,scope.month.length);
				}
				scope.checkDateFields(scope.day, scope.month, scope.year);
			});

			scope.$watch('year', function (newValue, oldValue) {
				if (newValue !== undefined && isNaN(parseFloat(newValue))) {
					scope.dateInvalid();
				} else if (newValue === undefined || (newValue && newValue.length >= 4)) {
					scope.checkDateFields(scope.day, scope.month, scope.year);
				}
			});

			scope.checkDateFields = function (d, m, y) {
				//if all fields have values
				if (d && m && y) {
					var date = m + "/" + d + "/" + y;
					//if date is valid
					if (scope.isStringValidDate(date)) {
						scope.dateValid(date);
					} else { //if date is invalid
						scope.dateInvalid();
					}
				} else if (!d && !m && !y) { //if all fields are empty => ignore
					scope.invalid = false;
				} else { //if at least one field is empty => invalid date
					scope.dateInvalid();
				}
			};

			scope.isStringValidDate = function (ds) {
				var d = new Date(ds);
				if (d && d.getFullYear() > 0) {
					return true;
				} else {
					return false;
				}
			};

			scope.dateInvalid = function () {
				scope.date = undefined;
				scope.invalid = true;
			};

			scope.dateValid = function (d) {
				scope.date = new Date(d);
				scope.invalid = false;
			};

			scope.openDate = function ($event) {
				stopProp($event);
				scope.dateOpen = true;
			};

			var stopProp = function ($event) {
				$event.preventDefault();
				$event.stopPropagation();
			};
		}
	};
}]);