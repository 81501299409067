angular.module('seller.directives')
    .directive('statementsVatInvoice', function() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/js/seller/directives/statements/vat-invoice.html',
            controller: function() {

            }
        };
    });