angular.module('inv.controllers')
.controller('AdminBillingCtrl',
	function ($scope, $location, $routeParams, Charges, Billing, Alerts) {
		$scope.paidCt = 1;
		$scope.unPaidCt = 1;
		$scope.draftCt = 1;
		$scope.rowsPerPage = 10;

		$scope.update = function () {
			Billing.paid({ page: $scope.paidCt, rows: $scope.rowsPerPage }, function(data) {
				$scope.paid = data;
			});
			Billing.unpaid({ page: $scope.unPaidCt, rows: $scope.rowsPerPage }, function(data) {
				$scope.unpaid = data;
			});

			Billing.draft({ page: $scope.draftCt, rows: $scope.rowsPerPage }, function(data) {
				$scope.draft = data;
			});
		};
		$scope.update();

		$scope.markAsPaid = function(id) {
			Charges.markAsPaid({ id: id }, function() {
				$scope.update();
			});
		};

		$scope.fetchRows = function(ct, type) {
			if (type === "paid") {
				$scope.paidCt = ct;
				Billing.paid({ page: ct, rows: $scope.rowsPerPage }, function(data) {
					$scope.paid = data;
				});
			} else if (type === "unpaid") {
				$scope.unPaidCt = ct;
				Billing.unpaid({ page: ct, rows: $scope.rowsPerPage }, function(data) {
					$scope.unpaid = data;
				});
			} else {
				$scope.draftCt = ct;
				Billing.draft({ page: ct, rows: $scope.rowsPerPage }, function(data) {
					$scope.draft = data;
				});
			}
		};

		$scope.showInvoice = function(id) {
			$location.path('billing/invoice/' + id);
		};

		$scope.sendFeeNote = function(feeNote) {
			var message = Alerts.confirm({
				header: 'Submit Fee Note #' + feeNote.number,
				message: 'Are you sure you want to submit the Fee Note #' + feeNote.number
			});

			message.result.then(function() {
				Charges.send({ id: feeNote.id }, function() {
					$scope.update();
				});
			});
		}
	}
);