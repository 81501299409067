angular.module('buyer.controllers')
.controller('DebtorSellerProfileCtrl', function($scope, $routeParams, $location, History) {
	//routes
	$scope.bidId = $routeParams.id;
	if($routeParams.username) {
		$scope.header = "Seller";
		$scope.profile = History.sellerProfile({ username: $routeParams.username });
		History.sellerHistory({ username: $routeParams.username }, function (data) {
			$scope.history = data.deals;
			$scope.averageCreditPeriod = data.averageCreditPeriod;
		});
	} else {
		$scope.header = "Debtor";
		$scope.profile = History.debtorProfile({ id: $routeParams.debtor });
		History.debtorHistory({ id: $routeParams.debtor }, function (data) {
			$scope.history = data.deals;
			$scope.averageCreditPeriod = data.averageCreditPeriod;
		});
	}
});