angular.module('inv.directives')
.directive('openingBalances', function(Position) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/openingBalances.html',
		scope: {
			stacked: '=',
			bid: '='
		},
		link: function($scope) {
			Position.balances(function(data) {
				$scope.balances = data.balances;
			});
		}
	}
});