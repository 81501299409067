angular.module('inv.directives').directive('activity', [
function() {
	return {
		template: '<i class="fa {{ cls }} fa-lg"></i>',
		restrict: 'E',
		scope: {
			status: '='
		},
		link: function(scope, elem, attrs) {
			scope.$watch('status', function(value) {
				if (value === 1) { // 1 = bid
					scope.cls = 'fa-money';
				} else if (value === 2) { // 2 = user logged in
					scope.cls = 'fa-user';
				} else if(value === 3) { // 3 = new user
					scope.cls = 'fa-users'; //'fa-user-plus' not working for some reason
				} else if (value === 4) { // 4 = invoice created
					scope.cls = 'fa-file-o';
				} else if (value === 5) { // 5 = invoice submitted
					scope.cls = 'fa-file-text-o';
				}
			});
		}
	}
}]);