// Bid Controller
angular.module('inv.controllers').controller('BidHistoryCtrl', [
	'$scope',
	'$routeParams',
	'Bid',
	function($scope, $routeParams, Bid) {
		//user type
		if (window.user.type === 'buyer') { $scope.isBuyer = true; }
		//breadcrumbs
		if($routeParams.deal) {
			$scope.param = $routeParams.deal;
			//level 1
			$scope.urlLevel1 = "#/deals";
			$scope.textLevel1 = "Deals";
			//level 2
			$scope.urlLevel2 = "#/deal-view/" + $routeParams.deal;
			$scope.textLevel2 = "View Deal #" + $routeParams.deal;
		} else if($routeParams.inv) {
			$scope.param = $routeParams.inv;
			//level 1
			$scope.urlLevel1 = "#/";
			$scope.textLevel1 = "Dashboard";
			//level 2
			$scope.urlLevel2 = "#/invoice-view/" + $routeParams.inv;
			$scope.textLevel2 = "View Invoice " + $routeParams.inv;
		} else {
			$scope.param = $routeParams.bid;
			//level 1
			$scope.urlLevel1 = "#/";
			$scope.textLevel1 = "Dashboard";
			//level 2
			$scope.urlLevel2 = "#/bid/" + $routeParams.bid;
			$scope.textLevel2 = "Bid on Deal #" + $routeParams.bid;
		}

		Bid.bidHistory({ invoiceId: $scope.param }, function(data) {
			$scope.history = data.history;
		});
	}
]);