angular.module('inv.services').factory('Bid',
	function($resource) {
		if(window.user.type === 'admin') {
			return $resource('api/admin/bid', {}, {
				bidHistory: { method: 'GET', url: 'api/admin/bid/history/:invoiceId', isArray: false }
			});
		}
		if(window.user.type === 'seller') {
			return $resource('api/seller/bid', {}, {
				bidHistory: { method: 'GET', url: 'api/seller/bid/history/:invoiceId', isArray: false }
			});
		}
		return $resource('api/buyer/bid', {}, {
			placeBid: { method: 'POST', url: 'api/buyer/bid/:invoiceId' },
			bidHistory: { method: 'GET', url: 'api/buyer/bid/history/:invoiceId', isArray: false }
		});
	}
);