angular.module('inv.directives')
.directive('textField', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/inputs/text-field.html',
		scope: {
			required: '@',
			field: '=',
			label: '@',
			placeholder: '@',
			icon: '@',
			name: '@'
		}
	}
});