angular.module('inv.services')
.factory('AuthTimer',
function($rootScope, $interval, AuthData) {
	var requests = 0;

	function request(config) {
		if(config.url.substr(-5) === '.html') {
			return config;
		}

		requests++;
		return config;
	}

	function response(resp) {
		if(resp.config.url.substr(-5) === '.html') {
			return resp;
		}

		requests--;

		if(requests === 0) {
			setTimers();
		}
		return resp;
	}

	function clearTimers() {
		$interval.cancel($rootScope.timeWarningFn);
		$interval.cancel($rootScope.logoutFn);
	}

	function setTimers() {
		clearTimers();

		var authData = AuthData.get();

		$rootScope.session = 900;
		var dt = new Date();
		dt.setSeconds(dt.getSeconds() + 900);
		console.log('[Timers] - Resetting Timer to: ' + dt.toTimeString());

		// set to logout in session, and warning a minute before that
		var logoutIn = $rootScope.session * 1000;
		var showMessageIn = ($rootScope.session - 60) * 1000;

		// set the timers on root scope
		$rootScope.timeWarningFn = $interval(showWarning, showMessageIn);
		$rootScope.logoutFn = $interval(logout, logoutIn);
	}

	function showWarning() {
		$rootScope.showWarning();
		$interval.cancel($rootScope.timeWarningFn);
	}

	function logout() {
		AuthData.remove();
		$interval.cancel($rootScope.logoutFn);
		window.location.href = '/logout';
	}

	return {
		'request': request,
		'response': response
	};
});