// Bid Controller
angular.module('inv.controllers').controller('NewBidCtrl', [
	'$scope',
	'$modalInstance',
	'invoice',
	'$modal',
	'Calculator',
	function($scope, $modalInstance, invoice, $modal, Calculator) {
		$scope.invoice = invoice;
		$scope.bid = {
			tranchePercentage: invoice.allocationMine.percentage ? invoice.allocationMine.percentage : 100
		};
		if(invoice.amount >= 10000) {
			$scope.tranches = true;
		} else {
			$scope.tranches = false;
		}

		$scope.$watch('bid.tranchePercentage', function(val) {
			if(val === undefined || val === 0)  {
				return;
			}

			$scope.bid.advance = undefined;
			var trancheNo = (val / 25) - 1;
			$scope.minBid = $scope.invoice.minimumBids[trancheNo];
			$scope.maxBid = $scope.invoice.receivable * (val / 100);

			if(val <= $scope.invoice.allocationFree.percentage) {
				$scope.popupText = val + '% of the reserve';
			} else if($scope.invoice.allocationFree.percentage > 0) {
				$scope.popupText = $scope.invoice.allocationFree.percentage + '% of the reserve & outbid ';
				$scope.popupText += (val - $scope.invoice.allocationFree.percentage) + '% of other bids';
			} else {
				$scope.popupText = 'outbid ' + val + '% of other bids';
			}
		});

		$scope.$watch('bid.advance', function(advance) {
			if(advance === undefined || goodBid() === false) {
				$scope.gain = null;
				$scope.net = null;
				$scope.gainP = null;
				$scope.netP = null;
				$scope.annYield = null;
				return;
			}

			$scope.gain = $scope.maxBid - advance;
			$scope.net = $scope.gain * 0.8;
			$scope.gainP = ($scope.gain / advance) * 100;
			$scope.netP = ($scope.net / advance) * 100;
			Calculator.netYieldAtAuction({ receivableSold: $scope.maxBid, bid: $scope.bid.advance, daysRemaining: $scope.invoice.daysRemaining }, function(data) {
				$scope.annYield = data.yield;
			});
		});

		$scope.setTranche = function(num) {
			$scope.bid.tranchePercentage = num;
		};

		var goodBid = function() {
			if(!$scope.bid.advance) {
				return false;
			}
			if($scope.bid.advance < $scope.minBid) {
				return false;
			}

			if($scope.bid.advance >= $scope.maxBid) {
				$scope.badBid = true;
				return false;
			}

			$scope.badBid = false;
			return true;
		};

		$scope.confirmBid = function() {
			$scope.confirm = false;

			if(goodBid() === false) {
				$scope.err = true;
				return;
			}

			$modalInstance.close($scope.bid);
		};

		$scope.cancel = function() {
			$modalInstance.dismiss('cancel');
		};
	}
]);