angular.module('inv.directives').directive('focusOn', function ($timeout) {
	return {
		restrict: 'A',
		priority: -100,
		link: function ($scope, $element, $attr) {
			$scope.$watch($attr.focusOn, function (_focusVal) {
				$timeout( function () {
					// for some reason $element not working
					var element = document.getElementById($attr.id);
					element.focus();
					element.select();
				});
			});
		}
	};
});