angular.module('seller.directives')
    .directive('statementsOpenDeals', function() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/js/seller/directives/statements/open-deals.html',
            controller: function() {

            }
        };
    });