angular.module('admin.controllers')
.controller('AuctionLiveCtrl',
	function($scope, $routeParams, $timeout, Invoice, $location) {
		$scope.showInvoice = function() {
			$location.path('auction-live-view/' + $routeParams.id + '/invoice-view/' + $scope.invoice.id);
		};
		// join socket
		$timeout(function() {
			window.events.join($routeParams.id);
		}, 1000);
		var initialLoad = true;

		$scope.reload = function() {
			if(initialLoad) {
				initialLoad = false;
			} else {
				$scope.showMessage = true;
			}

			$scope.invoice = Invoice.get({ id: $routeParams.id, showWinningOnly: false }, function() {
				$timeout(function() {
					$scope.showMessage = false
				}, 2000);
			});
		};

		$scope.reload();
	}
);