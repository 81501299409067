angular.module('regApp').controller('RegisterController',
    function($scope, $http, $modal, $timeout) {
        $scope.user = {
            type: window.type,
            debtors: [
                { name: null, number: null, ct: { name: "Ireland", code3: "IRL" }, radioCompanyNumber: true },
                { name: null, number: null, ct: { name: "Ireland", code3: "IRL" }, radioCompanyNumber: true },
                { name: null, number: null, ct: { name: "Ireland", code3: "IRL" }, radioCompanyNumber: true }
            ],
            isCompany: window.type === 'Buyer' ? false : true,
            files: {
                id: {},
                address: {}
            }
        };

        $scope.step1 = function() {
            $scope.heading = "Enter Your Details";
            $scope.step = 1;
        };

        // Load step 1 first
        $scope.step1();

        $scope.step2 = function() {
            if (checkForm($scope.frm1)) {
                if ($scope.user.type === 'Buyer' && !$scope.user.isCompany) {
                    $scope.heading = "Self Declaration as Professional Investor";
                    $scope.step = 7;
                } else {
                    $scope.heading = "Enter Company Details";
                    $scope.step = 2;
                }
            }
        };

        $scope.step7 = function() {
            $scope.user.acceptedProfessionalInvestor = true;
            $scope.heading = "Enter Investment Details";
            $scope.step = 3;
        };

        $scope.step3 = function() {
            if (checkForm($scope.frm2)) {
                $scope.heading = "Enter Debtor Details";
                $scope.step = 3;
            }
        };

        $scope.step4 = function() {
            var frm = $scope.frm3;
            if ($scope.user.type === 'Buyer' && $scope.user.isCompany) {
                frm = $scope.frm2;
            }

            validComapnyNumber = true;
            angular.forEach($scope.user.debtors, function(deb) {
                deb.validCompanyNumber = true;
                if (deb.radioCompanyNumber && deb.number != null && deb.number.toString().indexOf('/') !== -1) {
                    validComapnyNumber = false;
                    deb.validCompanyNumber = false;
                }
            });

            if (!validComapnyNumber) {
                $scope.invalid = true;
                return;
            } else { $scope.invalid = false; }

            if (checkForm(frm)) {
                $scope.heading = "Representation & Warranty";
                $scope.step = 4;
            }
        };

        $scope.step5 = function() {
            if ($scope.user.type === 'Buyer') {
                if ($scope.user.isCompany && !checkForm($scope.frm2)) {
                    return;
                }

                if (!$scope.user.isCompany && !checkForm($scope.frm3)) {
                    return;
                }
            }

            $scope.url = 'rules/platform-rules/InvoiceFair%20Platform%20Rules.pdf';
            $scope.user.acceptedReps = true;
            $scope.heading = "Rules of Use";
            $scope.step = 5;
        };

        $scope.step6 = function() {
            $scope.user.acceptedRules = true;
            $scope.heading = "Verification & Security";

            // country fields
            if ($scope.user.contactPrefix) {
                $scope.user.contactPrefix = $scope.user.contactPrefix.phone;
                $scope.user.contactNumber = $scope.user.contactPhone;
            }
            if ($scope.user.companyCountry) {
                var country = $scope.user.companyCountry;
                $scope.user.companyCountry = country.name;
                $scope.user.countryCode = country.code3;
            }
            if ($scope.user.country) { $scope.user.country = $scope.user.country.name; }
            if ($scope.user.countryOfResidence) { $scope.user.countryOfResidence = $scope.user.countryOfResidence.name; }
            if ($scope.user.nationality) { $scope.user.nationality = $scope.user.nationality.name; }
            angular.forEach($scope.user.debtors, function(d) {
                d.countryCode = d.ct.code3;
                d.country = d.ct.name;
            });

            // do file upload and post
            $scope.completing = true;
            $scope.step = 0;
            $http.post('/api/register', $scope.user)
                .success(function() {
                    uploadFiles(function() {
                        $scope.completing = false;
                        $scope.auth = true;
                        $scope.authStep1 = true;
                        $scope.user.country = { name: "Ireland", code3: "IRL", phone: "353" };
                    });
                })
                .error(function() {
                    $scope.completing = false;
                    $scope.errorComplete = true;
                });
        };

        $scope.authStep = function() {
            $scope.sendingCode = true;
            $scope.codeSent = false;
            $scope.authStep1 = false;
            var url = '/api/register/changephone';

            $http.post(url, { email: $scope.user.email, phone: $scope.user.phone, countryCode: $scope.user.country.phone })
                .success(function() {
                    $scope.sendingCode = false;
                    $scope.codeSent = true;
                    $scope.code = "";
                    $scope.invalidToken = false;
                })
                .error(function() {
                    $scope.sendingCode = false;
                    $scope.errorComplete = true;
                });
        };

        $scope.resendCode = function() {
            $http.post('/api/register/resendtext', { email: $scope.user.email, phone: $scope.user.phone, countryCode: $scope.user.country.phone });
            $scope.authStep();
        };

        $scope.startOver = function() {
            $scope.codeSent = false;
        };

        $scope.verifyPhone = function() {
            $scope.sendingCode2 = true;
            $scope.invalidToken = false;
            var url = '/api/register/confirmphone';

            $http.post(url, { email: $scope.user.email, phone: $scope.user.phone, countryCode: $scope.user.country.phone, code: $scope.code })
                .success(function() {
                    $scope.sendingCode2 = false;
                    $scope.step = 6;
                    $scope.auth = false;
                })
                .error(function() {
                    $scope.sendingCode2 = false;
                    $scope.invalidToken = true;
                });
        };

        $scope.checkUsername = function() {
            if (!$scope.user.email) {
                return;
            }

            var url = "/api/register/checkusername/" + $scope.user.email;
            $scope.userLoading = true;
            $http.get(url)
                .success(function() {
                    $scope.userLoading = false;
                    $scope.frm1.email.$setValidity('namecheck', true);
                })
                .error(function(err) {
                    $scope.userLoading = false;
                    $scope.frm1.email.$setValidity('namecheck', false);
                });
        };

        $scope.applyFileToScope = function(files, type) {
            var fd = new FormData();
            fd.append("file", files[0]);

            if (type === 1) {
                $scope.user.files.id.data = fd;
                $scope.user.files.id.file = files[0];
            } else {
                $scope.user.files.address.data = fd;
                $scope.user.files.address.file = files[0];
            }

            $scope.$apply();
        };

        var uploadFiles = function(callback) {
            var idUrl = '/api/register/uploadid/' + $scope.user.email;
            var addressUrl = '/api/register/uploadaddress/' + $scope.user.email;

            uploadFile(idUrl, $scope.user.files.id.data, function() {
                uploadFile(addressUrl, $scope.user.files.address.data, callback);
            })
        };

        var uploadFile = function(url, fileData, callback) {
            $http.post(url, fileData, {
                headers: { 'Content-Type': undefined },
                transformRequest: angular.identity
            }).success(callback);
        };

        $scope.searchDebtor = function(debtor, index) {
            var modal = $modal.open({
                templateUrl: '/partials/modals/findDebtor.html',
                controller: 'FindDebtorController',
                resolve: {
                    debtor: function() {
                        return debtor;
                    }
                }
            });

            var inx = index;
            modal.result.then(function(result) {
                $scope.user.debtors[inx].number = result.number;
            });
        };

        $scope.email = function(type) {
            var url = '/api/register/email' + type + '?email=' + $scope.user.email + '&name=' + $scope.user.name;

            $scope.sendingMail = true;
            $http.post(url)
                .success(function() {
                    $scope.sendingMail = false;
                });
        };

        $scope.goBack = function() {
            // check for difference when an indivdual
            if (!$scope.user.isCompany) {
                switch ($scope.step) {
                    case 3:
                        $scope.step2();
                        break;
                    case 4:
                        $scope.step5();
                        break;
                    case 5:
                        $scope.step7();
                        break;
                    case 7:
                        $scope.step1();
                        break;
                }
                return;
            }

            switch ($scope.step) {
                case 2:
                    $scope.step1();
                    break;
                case 3:
                    $scope.step2();
                    break;
                case 4:
                    if ($scope.user.type === 'Buyer') { $scope.step5(); } else { $scope.step3(); }
                    break;
                case 5:
                    if ($scope.user.type === 'Buyer') { $scope.step2(); } else { $scope.step4(); }
                    break;
            }
        };

        var checkForm = function(form) {
            $scope.frmClicked = true;
            form.$setDirty();
            if (!$scope.user.files.id.file || !$scope.user.files.address.file || form.$valid === false) {
                $scope.invalid = true;
                return false;
            }

            $scope.invalid = false;
            return true;
        }
    });