angular.module('inv.services')
.factory('AuthService',
function($q, $http, AuthData) {
	var _login = function(username, password) {
		var deferred = $q.defer();
		var data = 'grant_type=password&username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password);
		var headers = {
			'Content-Type': 'application/x-www-form-urlencoded'
		};

		$http.post('/token', data, { headers: headers })
			.success(function(response) {
				AuthData.set({
						token: response.access_token,
						expires: response.expires_in
				});
				deferred.resolve(response);
			})
			.error(function(err, status) {
				deferred.reject(err);
			});

		return deferred.promise;
	};

	var _logout = function() {
		AuthData.remove();
	};

	return {
		login: _login,
		logout: _logout
	};
});