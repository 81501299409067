angular.module('inv.services')
.factory('Dashboard',
function($resource) {
	if(window.user.type === 'buyer') {
		return $resource('api/buyer/dashboard', null, {
			auctions: { url:'api/buyer/dashboard/auctions', method:'GET'},
			winning: { url:'api/buyer/dashboard/winning/:id', method:'GET', isArray: true},
			loosing: { url:'api/buyer/dashboard/loosing/:id', method:'GET', isArray: true},
			atAuction: { url:'api/buyer/dashboard/atauction/:id', method:'GET', isArray: true},
			upcoming: { url:'api/buyer/dashboard/upcoming/:id', method:'GET', isArray: true},
		});
	}
	return $resource('api/seller/dashboard');
});