angular.module('buyer.controllers')
.controller('AuctionCtrl', function($scope, $routeParams, Auction) {
	$scope.refresh = function() {
		$scope.auction = Auction.get( { id: $routeParams.id });
	}
	$scope.refresh();

	$scope.selectDeal = function (id) {
		$scope.selectedDeal = id;
	}

	$scope.unselect = function () {
		$scope.selectedDeal = '';
	}
});