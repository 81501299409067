angular.module('admin.controllers')
.controller('CompanyCtrl',
function($scope, $http) {
	$scope.search = {
		functionNumber: "1",
		name: "InvoiceFair",
		nationalNumber: "546341"
	};

	$scope.doSearch = function() {
		$scope.searching = true;
		$http.post('/api/company', $scope.search)
			.success(function(data) {
				$scope.searching = false;
				$scope.result = data;
			});
	};

	$scope.fullDetails = function() {
		$scope.search.nationalNumber = $scope.result.QueryResponse.Result.NationalNumber;
		$scope.functionNumber = "3";

		$scope.doSearch();
	};

});