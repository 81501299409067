angular.module('inv.services').factory('Times',
function() {

	var getDiff = function(date, other) {
		if(other === undefined) {
			other = moment();
		}

		// TODO : there's probably a method in moment.js for this
		var dt = moment(date);
		var dayDiff = dt.diff(other, 'days');
		var hoursDiff = dt.diff(other, 'hours');
		var minutesDiff = dt.diff(other, 'minutes');
		var secondsDiff = dt.diff(other, 'seconds');

		if(dayDiff > 1) {
			return dayDiff + ' days';
		} else if (hoursDiff > 1) {
			return hoursDiff + ' hours';
		} else if (minutesDiff > 1) {
			return minutesDiff + ' minutes';
		}

		return dt.diff(other, 'seconds') + ' seconds';
	};

	return {
		getDiff: getDiff
	};
});