angular.module('admin.controllers')
.controller('AuctionsCtrl',
	function($scope, $location, $timeout, Auction, dateFilter) {
		$scope.getAuctions = function() {
			Auction.get(function(data) {
				$scope.current = data.current;
				$scope.next = data.next;
				$scope.previous = data.previous;
				$scope.editable = data.editable;
			});
		};
		$scope.getAuctions();
		$scope.buyers = Auction.buyers();
		$scope.newAuction = {
			startTime: "10:00",
			endTime: "14:00"
		};
		$scope.initialAuction = angular.copy($scope.newAuction);

		$scope.viewAuction = function(id) {
			$location.path('auction-view/'+ id);
		};

		$scope.saveAuction = function() {
			if (!$scope.newAuction.id) {
				Auction.addAuction($scope.newAuction, function() {
					$scope.showBubble("New Auction Added", "New Auction created for " + $scope.newAuction.date + " from " + $scope.newAuction.startTime + " to " + $scope.newAuction.endTime);
					$scope.cancelAuction();
					$scope.getAuctions();
				});
			} else {
				Auction.updateAuction($scope.newAuction, function() {
					$scope.showBubble("Auction Updated", "Auction #" + $scope.newAuction.id + " was updated");
					$scope.cancelAuction();
					$scope.getAuctions();
				});
			}
		};

		$scope.cancelAuction = function() {
			$scope.addAuction = false;
			$scope.newAuction = angular.copy($scope.initialAuction);
		};

		$scope.editAuction = function(auction) {
			$scope.newAuction.id = auction.id;
			$scope.newAuction.date = auction.start;
			$scope.newAuction.startTime = auction.start.substr(11,5);
			$scope.newAuction.endTime = auction.end.substr(11,5);
			$scope.addAuction = true;
		};

		$scope.closeAuction = function() {
			Auction.closeAuction(function() {
				$scope.cancelAuction();
				$scope.getAuctions();
				$scope.auctionClosing = true;
			});
		};

		$scope.showBubble = function(title, message) {
			$scope.growl = { title: title, message: message };
			$scope.updated = true;
			$timeout(function() {
				$scope.updated = false;
			}, 4000);
		};

		$scope.generateDropdownTimes = function() {
			$scope.timeItems = [];
			var minutes = ["00", "15", "30", "45"];
			var hours = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "00"];
			for (var i = 0; i < hours.length; i++) {
				for (var j = 0; j < minutes.length; j++) {
					$scope.timeItems.push(hours[i]+":"+minutes[j]);
				}
			}
		}
		$scope.generateDropdownTimes();
	}
);