angular.module('inv.services')
.factory('LoadingService',
	function($q, $rootScope) {
		$rootScope.counter = 0;
		var loading = function() {
			$rootScope.counter++;
			$rootScope.loading = true;
		};

		var notLoading = function() {
			$rootScope.counter--;
			if($rootScope.counter === 0) {
				$rootScope.loading = false;
			}
		};

		return {
			'request': function (config) {
				loading();
				return config;
			},
			'response': function (response) {
				notLoading();
				return response;
			},
			'responseError': function (rejection) {
				notLoading();
				return $q.reject(rejection);
			}
		};
	}
);