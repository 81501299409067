angular.module('admin.controllers')
.controller('DealsCtrl',
	function($scope, Deals, $location) {
		$scope.openCt = 1;
		$scope.doneCt = 1;
		$scope.rowsPerPage = 10;

		$scope.update = function() {
			Deals.agreed({ page: $scope.openCt, rows: $scope.rowsPerPage }, function(data) {
				$scope.open = data;
			});
			Deals.done({ page: $scope.doneCt, rows: $scope.rowsPerPage }, function(data) {
				$scope.done = data;
			});
		};
		$scope.update();

		$scope.showDeal = function(id) {
			$location.path('deal-view/' + id);
		};

		$scope.markAsPaid = function(id) {
			Deals.markAsPaid({id: id}, function() {
				$scope.update();
			});
		};

		$scope.fetchRows = function(ct, type) {
			if (type === "open") {
				$scope.openCt = ct;
				Deals.agreed({ page: ct, rows: $scope.rowsPerPage }, function(data) {
					$scope.open = data;
				});
			} else {
				$scope.doneCt = ct;
				Deals.done({ page: ct, rows: $scope.rowsPerPage }, function(data) {
					$scope.done = data;
				});
			}
		};
	}
);