angular.module('seller.controllers')
.controller('ViewInvoiceCtrl',
	function($scope, $routeParams, $timeout, $interval, $filter, $window, Invoice, Deals, Bid, $location) {
		// join socket
		$timeout(function() {
			window.events.join($routeParams.id);
		}, 1000);

		$scope.invoice = Invoice.get({ id: $routeParams.id });

		$scope.showBidHistory = function () {
			$location.path('invoice-view/' + $routeParams.id + '/bid-history');
		};

		$scope.filterItems = function(item) {
			if($scope.invoice.status < 5) {
				return true;
			}

			return item.loosing === false;
		};

		$scope.refresh = function() {
			$scope.invoice = Invoice.get({ id: $routeParams.id });
		};
	}
);
