angular.module('inv.directives')
.directive('fileField', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/js/directives/inputs/file-field.html',
		scope: {
			required: '@',
			field: '=',
			label: '@',
			placeholder: '@',
			name: '@',
			multiple: '@',
			type: '@',
			fileUpdated: '&'
		},
		controller: function($scope, $element) {
			$scope.$on('file:changed', function (event, data) {
				if($scope.type === data) {
					$element.find("input")[0].value = null;
				}
			});

			$scope.$on('files:changed', function (event, data) {
				if($scope.type === data) {
					$element.find("input")[1].value = null;
				}
			});

			$scope.applyFileToScope = function(file, type) {
				var fd = new FormData();
				fd.append("file", file);
				$scope.$apply();
				$scope.fileUpdated({ file: file, data: fd });
			};

			$scope.applyFilesToScope = function(files, type) {
				var fd = new FormData();
				angular.forEach(files, function(f) {
					fd.append("file", f);
					$scope.$apply();
					$scope.fileUpdated({ file: f, data: fd });
				});
			};
		}
	}
});