angular.module('inv.services')
.factory('Invoice', function($resource) {
	if(window.user.type === 'buyer') {
		return $resource('api/buyer/invoice/:id', { id: '@id' });
	}
	if(window.user.type === 'seller') {
		return $resource('api/seller/invoice/:id', { id: '@id', step: '@step' }, {
			delete : { method: 'POST', url : 'api/seller/invoice/:id/delete' },
			status : { method: 'GET', url : 'api/seller/invoice/status/:status', isArray: true }
		});
	}

	return $resource('api/admin/invoice/:id', { id: '@id' }, {
		approve : { method: 'POST', url : 'api/admin/invoice/:id/approve/:auctionid' },
		reject : { method: 'POST', url : 'api/admin/invoice/:id/reject' }
	});
});