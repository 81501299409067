angular.module('inv.directives').directive('creditScore', [
function() {
	return {
		template: '<span class="credit-score {{ cls }}">{{ score }}</span>',
		restrict: 'E',
		scope: {
			score: '='
		},
		link: function(scope, elem, attrs) {
			scope.$watch('score', function(value) {
				if (value >= 0 && value < 1) {
					scope.cls = 'bg-black';
				} else if (value >= 1 && value <= 20) {
					scope.cls = 'bg-red';
				} else if(value >= 21 && value <= 40) {
					scope.cls = 'bg-orange-dark';
				} else if (value >= 41 && value <= 60) {
					scope.cls = 'bg-orange-light';
				} else if (value >= 61 && value <= 80) {
					scope.cls = 'bg-blue';
				} else if(value >= 81) {
					scope.cls = 'bg-green';
				}
			});
		}
	}
}]);
