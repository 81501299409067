angular.module('buyerApp')
.config(function($httpProvider, datepickerConfig, datepickerPopupConfig) {
	$httpProvider.interceptors.push('AuthInterceptor');
	$httpProvider.interceptors.push('AuthTimer');
	$httpProvider.interceptors.push('LoadingService');
	$httpProvider.interceptors.push('ErrorService');
	// configure date popup
	datepickerConfig.showWeeks = false;
	datepickerPopupConfig.toggleWeeksText = null;
	datepickerPopupConfig.datepickerPopup = "dd/MM/yyyy";
})
.run(function($rootScope, Alerter, $http, $location) {
	$rootScope.showPosition = window.user.showPosition;
	$rootScope.showCharts = window.user.showCharts;

	$rootScope.showWarning = function () {
		Alerter.alert('login', {
			controller: 'loginCtrl'
		});
	}

	$rootScope.$on('$routeChangeSuccess', function(ev, current) {
		$rootScope.currentUrl = $location.path();
	});

	$rootScope.checkUrl = function(against) {
		if(!$rootScope.currentUrl) { return false; }
		if(against === '/') {
			return $rootScope.currentUrl === '/';
		} else {
			return $rootScope.currentUrl.startsWith(against);
		}
	};
});