angular.module('inv.services')
.factory('AuthInterceptor',
function($q, $rootScope, $timeout, AuthData) {
	// on request
	// 	- set header
	//	- count ++

	// on response
	// 	- count --
	//  - if count == 0
	// 		- reset token
	function request(config) {
		config.headers = config.headers || {};
		var authData = AuthData.get();

		if(authData) {
			config.headers.Authorization = 'Bearer ' + authData.token;
		}

		return config;
	}

	return {
		'request': request
		// 'response': response
	};
});