angular.module('buyer.controllers')
.controller('InvoiceCtrl',
	function($scope, $routeParams, $location, $window, Invoice, Deals, Alerts, CompanyReport) {
		$scope.invoice = Invoice.get({ id: $routeParams.id });

		$scope.companyReport = function(number, type) {
			if(type === 'debtor') {
				if($scope.invoice.debtorReportDocument) {
					$window.open($scope.invoice.debtorReportDocument.url);
				}  else {
					Alerts.warning({
						header: 'No Report Available',
						message: "No Company Report available at the moment."
					});
				}
			} else {
				if($scope.invoice.sellerReportDocument) {
					$window.open($scope.invoice.sellerReportDocument.url);
				}  else {
					Alerts.warning({
						header: 'No Report Available',
						message: "No Company Report available at the moment."
					});
				}
			}
		};

		$scope.goToAuction = function(invoiceId) {
			$location.path('/bid/' + invoiceId);
		};

		$scope.showBidHistory = function () {
			$location.path('invoice-view/' + $scope.invoice.id + '/bid-history');
		};
	}
);