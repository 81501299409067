// email / password match
angular.module('inv.directives').directive('equals', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) { return; }

            //watch own value and re-validate on change
            scope.$watch(attrs.ngModel, function () {
                validate();
            });

            //observe the other value and re-validate on change
            attrs.$observe('equals', function (val) {
                validate();
            });

            var validate = function () {
                var val1 = ngModel.$viewValue;
                var val2 = attrs.equals;

                ngModel.$setValidity('equals', !val1 || !val2 || val1 === val2);
            };
        }
    };
});