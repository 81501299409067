angular.module('inv.services')
.factory('EmailType', function($resource) {
	var userType = window.user.type;
	return $resource('api/'+ userType +'/emailtype', {}, {
		delete: { method:'DELETE', url:'api/'+ userType +'/emailtype' },
		update: { method:'POST', url:'api/'+ userType +'/emailtype' },
		notificationSettings: { method:'GET', url:'api/'+ userType +'/emailtype/notification-settings' },
		emailNotifications: { method:'POST', url:'api/'+ userType +'/emailtype/email-notifications' },
		marketingEmailNotifications: { method:'POST', url:'api/'+ userType +'/emailtype/marketing-email-notifications' }
	});
});