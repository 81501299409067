angular.module('buyer.controllers')
.controller('DealCtrl',
	function($scope, $location, Deals, $routeParams) {
		$scope.deal = Deals.get({ id: $routeParams.id });

		$scope.showBidHistory = function () {
			$location.path('deal-view/' + $routeParams.id + '/bid-history');
		};

		$scope.showInvoice = function () {
			$location.path('invoice-view/' + $routeParams.id);
		};
	}
);