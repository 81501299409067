angular.module('inv.controllers')
.controller('BankAccountsCtrl',
	function ($scope, $location, BankAccounts, Alerts) {
		$scope.bankAccounts = BankAccounts.query();

		$scope.addAccount = function() {
			$location.path('account/bank-accounts/new-bank-account');
		};

		$scope.editBankAccount = function(id) {
			$location.path('account/bank-accounts/edit-bank-account/' + id);
		};

		$scope.deleteBankAccount = function(acc) {
			var message = Alerts.confirm({
				header: 'Delete ' + acc.currency + ' Bank Account',
				message: 'Are you sure you want to delete the ' + acc.currency + " bank account?"
			});

			message.result.then(function() {
				acc.$remove(function() {
					$scope.bankAccounts = BankAccounts.query();
				});
			});
		};
	}
)
.controller('BankAccountCtrl',
	function ($scope, $location, $routeParams) {
		if($routeParams.id) {
			$scope.accountId = $routeParams.id;
		}

		$scope.cancel = function() {
			$location.path('account/bank-accounts');
		};

		$scope.save = function() {
			$location.path('account/bank-accounts');
		}
	}
);