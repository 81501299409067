angular.module('inv.services')
.factory('ErrorService', function($q, $injector) {
	return {
		'responseError': function (rejection) {
			$injector.invoke(function($modal) {
				var options;

				// TODO : replace this
				if(rejection.data.message.includes("To bid on this deal you need to have sufficient funds")) {
					options = {
						header: 'Issue Encountered',
						message: rejection.data.message
					};

					$modal.open({
						templateUrl: '/partials/modals/fx-bid.html',
						controller: 'FxBidController',
						size: 'lg',
						resolve: {
							options: function() {
								return options;
							}
						}
					});
					return;
				}
				
                // err msg has already displayed when saving pwd (accountCtrl.js)
                if(rejection.data.message.includes("Your old password is incorrect")) {
                    return;
                }


				if(rejection.status === 400) {
					options = {
						header: 'Issue Encountered',
						message: rejection.data.message.replace(/\n/g, '<br>'),
						warning: true
					};
				} else {
					options = {
						header: 'Error Encountered',
						message: 'We encountered an error. Please try again and if the problem presists please contact IT Support',
						danger: true
					};
				}

      	$modal.open({
          templateUrl: '/partials/modals/message.html',
          backdrop: 'static',
          controller: 'ModalCtrl',
					resolve: {
						options: function() {
							return options;
						}
					}
        });
    	});
			return $q.reject(rejection);
		}
	};
});