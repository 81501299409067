angular.module('inv.services')
.factory('AuthData',
function(localStorageService) {

	var key = 'authorizationData';
	function getData() {
		return localStorageService.get(key);
	}
	function setData(data) {
		localStorageService.set(key, data);
	}
	function removeData() {
		localStorageService.remove(key);
	}

	return {
		get: getData,
		set: setData,
		remove: removeData
	};
});