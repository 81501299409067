angular.module('buyerApp')
.config(function($routeProvider) {
	$routeProvider
		.when('/rules', {
			controller: 'RulesCtrl', templateUrl: 'scripts/shared/rules-page.html' })
		.when('/bid/:id', {
			controller: 'BidCtrl', templateUrl: 'scripts/buyer/bid.html' })
		.when('/bid/:id/seller-profile/:username', {
			controller: 'DebtorSellerProfileCtrl', templateUrl: 'scripts/buyer/debtorSellerProfile.html' })
		.when('/bid/:id/debtor-profile/:debtor', {
			controller: 'DebtorSellerProfileCtrl', templateUrl: 'scripts/buyer/debtorSellerProfile.html' })
		.when('/invoice-view/:id', {
			controller: 'InvoiceCtrl', templateUrl: 'scripts/buyer/view-invoice.html' })
		.when('/deal-view/:id', {
			controller: 'DealCtrl', templateUrl: 'scripts/buyer/deal.html' })
		.when('/invoice-doc/:id/:type/:doc', {
			controller: 'ViewInvoiceDocCtrl', templateUrl: 'scripts/shared/view-invoice-doc.html' })
		.when('/auction', {
			controller: 'AuctionsCtrl', templateUrl: 'scripts/buyer/auctions.html' })
		.when('/auction/:id', {
			controller: 'AuctionCtrl', templateUrl: 'scripts/buyer/auction.html' })
		.when('/deals', {
			controller: 'DealsCtrl', templateUrl: 'scripts/buyer/deals.html' })
		.when('/account', {
			controller: 'MyAccountCtrl', templateUrl: 'scripts/shared/myAccount.html' })
		.when('/billing/:id', {
			controller: 'BillingCtrl', templateUrl: 'scripts/shared/billing.html' })
		.when('/deal-view/:deal/bid-history', {
			controller: 'BidHistoryCtrl', templateUrl: 'scripts/shared/bidHistory.html' })
		.when('/bid/:bid/bid-history', {
			controller: 'BidHistoryCtrl', templateUrl: 'scripts/shared/bidHistory.html' })
		.when('/invoice-view/:inv/bid-history', {
			controller: 'BidHistoryCtrl', templateUrl: 'scripts/shared/bidHistory.html' })
		.when('/account/bank-accounts', {
			controller: 'BankAccountsCtrl', templateUrl: 'scripts/shared/bankAccounts.html' })
		.when('/account/bank-accounts/new-bank-account', {
			controller: 'BankAccountCtrl', templateUrl: 'scripts/shared/bankAccount.html' })
		.when('/account/bank-accounts/edit-bank-account/:id', {
			controller: 'BankAccountCtrl', templateUrl: 'scripts/shared/bankAccount.html' })
		.when('/rates', { templateUrl: 'scripts/buyer/rates.html' })
		.when('/transactions', {
			controller: 'TransactionCtrl', templateUrl: 'scripts/buyer/transactions.html' })
		.when('/', {
			controller: 'HomeCtrl', templateUrl: 'scripts/buyer/home.html' })
		.otherwise({ redirectTo: '/'});
});