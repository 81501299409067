angular.module('buyer.directives')
.directive('auctionGrid', function () {
	return {
		templateUrl: 'app/js/buyer/directives/auction-grid.html',
		restrict: 'E',
		scope: {
			auctions: '=',
			header: '@',
			subHeader: '@',
			showWinningBids: '@'
		}
	}
});
