angular.module('buyer.directives')
.directive('buyerMaturity', function () {
	return {
		templateUrl: 'app/js/buyer/directives/buyer-maturity.html',
		restrict: 'E',
		scope: {
		},
		controller: function($scope, Chart) {
      $scope.opts =  {
        chart: {
          type: 'multiBarHorizontalChart',
          height: 40,
          showYAxis: false,
          showXAxis: false,
          margin: {
            top: 0,
            right: 50,
            left: 0,
            bottom: 0
          },
          y: function(d){
            return d.value;
          },
          yAxis: {
            tickFormat: function(d){
              return d3.format('.02f')(d);
            }
          },
          showLegend: false,
          showControls: false,
          showValues: false,
          duration: 500,
          stacked: true,
        }
      };

      Chart.maturity(function(data) {
        $scope.data = data.chartData;
        $scope.total = data.totalReceivable;
      });
		}
	}
})
