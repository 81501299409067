angular.module('inv.controllers')
.controller('ViewInvoiceDocCtrl',
	function($scope, $routeParams, Invoice, $window) {
		$scope.invoice = Invoice.get({ id: $routeParams.id }, function() {
			var docId = parseInt($routeParams.doc);
			var docType = $routeParams.type;

			if(docType === 'document') {
				angular.forEach($scope.invoice.documents, function(doc) {
					if(doc.id === docId) {
						$scope.doc = doc;
					}
				});
			} else if(docType === 'debtor') {
				angular.forEach($scope.invoice.debtorDocuments, function(doc) {
					if(doc.id === docId) {
						$scope.doc = doc;
					}
				});
			} else if(docType === 'redacted') {
				angular.forEach($scope.invoice.redactedDocuments, function(doc) {
					if(doc.id === docId) {
						$scope.doc = doc;
					}
				});
			}
		});

		$scope.availHeight = $window.innerHeight;
		$scope.docIsImage = function() {
			if(!$scope.doc || !$scope.doc.fileType) {
				return false;
			}

			return $scope.doc.fileType.indexOf('image') === 0;
		};
	}
);