angular.module('seller.controllers')
.controller('HomeCtrl',
	function ($scope, $location, $interval, Dashboard, Profile, Alerts, BankAccounts, Charges, Invoice) {
		$scope.detailsLoaded = false; // ng-cloak not working
		Dashboard.get(function (data) {
			$scope.current = data.auctionData.current;
			$scope.rejected = data.rejected;
			$scope.notApproved = data.notApproved;
			$scope.next = data.auctionData.next;
			$scope.notSubmitted = data.notSubmitted;
			$scope.underReview = data.underReview;
			$scope.updated = moment().format();
		});

		Profile.get(function(data) {
			$scope.passDaysLeft = data.passwordDaysLeft;
		});

		$scope.bankAccounts = BankAccounts.query(function() {
			$scope.detailsLoaded = true;
		});

		Charges.newInvoices(function(data) {
			$scope.newInvoices = data.newFeeNotes;
		});

		var timer = $interval(function() {
			if($scope.current && $scope.current.end) {
				var now = moment().utc();
				var end = moment($scope.current.end);
				var left = end.diff(now);

				if(left < 0) {
					$scope.remaining = null;
					$interval.cancel(timer);
				}
				else {
					$scope.remaining = moment(left).format();
					if(left < 300000)  {
						$scope.timeDanger = true;
					}
				}
			}
		}, 1000);

		$scope.selectInvoice = function (invoice) {
			if(invoice.status === 0) {
				$location.path('invoice/' + invoice.id);
			} else {
				$location.path('invoice-view/' + invoice.id);
			}
		};

		$scope.delete = function (id, index, rejected) {
			Invoice.delete({ id: id }, function() {
				if (rejected) { $scope.rejected.splice(index, 1); }
				else { $scope.notApproved.splice(index, 1); }
			});
		};

		$scope.edit = function (id) {
			$location.path('invoice/' + id);
		};

		$scope.view = function (id) {
			$location.path('invoice-view/' + id);
		};

		$scope.newInvoice = function () {
			$location.path('invoice/');
		};

		$scope.goToAccount = function() {
			$location.path('account');
		};

		$scope.goToBankAccounts = function() {
			$location.path('account/bank-accounts');
		};
	}
);