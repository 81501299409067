angular.module('adminApp')
.config(function($routeProvider) {
	$routeProvider
		.when('/company', {
			templateUrl: 'scripts/admin/company.html', controller: 'CompanyCtrl' })
		.when('/billing/:id', {
			templateUrl: 'scripts/admin/billing-details.html', controller: 'BillingDetailsCtrl' })
		.when('/billing/:username/invoice/:id', {
			templateUrl: 'scripts/shared/billing.html', controller: 'BillingCtrl' })
		.when('/billing/:username/new', {
			templateUrl: 'scripts/admin/fee-note.html', controller: 'FeeNoteCtrl' })
		.when('/billing/:username/invoice/:id/edit', {
			templateUrl: 'scripts/admin/fee-note.html', controller: 'FeeNoteCtrl' })
		.when('/billing/invoice/:id', {
			templateUrl: 'scripts/shared/billing.html', controller: 'BillingCtrl' })
		.when('/auctions', {
			templateUrl: 'scripts/admin/auctions.html', controller: 'AuctionsCtrl' })
		.when('/auction-live-view/:id', {
			templateUrl: 'scripts/admin/auction-live-view.html', controller: 'AuctionLiveCtrl' })
		.when('/auction-view/:id', {
			templateUrl: 'scripts/admin/auction-view.html', controller: 'AuctionCtrl' })
		//debtor - from debtors
		.when('/debtor/:id', {
			templateUrl: 'scripts/admin/debtor.html', controller: 'DebtorCtrl' })
		//debtor - from user
		.when('/userinfo/:username/debtor/:id', {
			templateUrl: 'scripts/admin/debtor.html', controller: 'DebtorCtrl' })
		.when('/debtors', {
			templateUrl: 'scripts/admin/debtors.html', controller: 'DebtorsCtrl' })
		//to do: refactor breadcrumbs across the app, current setup cannot suport proper history
		//invoice - from dashboard
		.when('/invoice-view/:id', {
			templateUrl: 'scripts/admin/view-invoice.html', controller: 'InvoiceCtrl' })
		//invoice - from user
		.when('/userinfo/:username/invoice-view/:id', {
			templateUrl: 'scripts/admin/view-invoice.html', controller: 'InvoiceCtrl' })
		//invoice - from debtor
		.when('/debtor/:debtorId/invoice-view/:id', {
			templateUrl: 'scripts/admin/view-invoice.html', controller: 'InvoiceCtrl' })
		//invoice - from auction
		.when('/auction-view/:auctionId/invoice-view/:id', {
			templateUrl: 'scripts/admin/view-invoice.html', controller: 'InvoiceCtrl' })
		//invoice - from live auction
		.when('/auction-live-view/:auctionLiveId/invoice-view/:id', {
			templateUrl: 'scripts/admin/view-invoice.html', controller: 'InvoiceCtrl' })
		//deal - from deals
		.when('/deal-view/:id', {
			templateUrl: 'scripts/admin/view-deal.html', controller: 'DealCtrl' })
		//deal - from user
		.when('/userinfo/:username/deal-view/:id', {
			templateUrl: 'scripts/admin/view-deal.html', controller: 'DealCtrl' })
		.when('/invoice-doc/:id/:type/:doc', {
			templateUrl: 'scripts/shared/view-invoice-doc.html', controller: 'ViewInvoiceDocCtrl' })
		.when('/users', {
			templateUrl: 'scripts/admin/users.html', controller: 'UsersCtrl' })
		//user - from users
		.when('/userinfo/:username', {
			templateUrl: 'scripts/admin/user.html', controller: 'UserCtrl' })
		//user - from debtor
		.when('/debtor/:debtorId/userinfo/:username', {
			templateUrl: 'scripts/admin/user.html', controller: 'UserCtrl' })
		.when('/deals', {
			templateUrl: 'scripts/admin/deals.html', controller: 'DealsCtrl' })
		.when('/invoice-view/:inv/bid-history', {
			controller: 'BidHistoryCtrl', templateUrl: 'scripts/shared/bidHistory.html' })
		.when('/search', {
			templateUrl: 'scripts/admin/search.html', controller: 'SearchCtrl' })
		.when('/billing', {
			templateUrl: 'scripts/admin/billing.html', controller: 'AdminBillingCtrl' })
		.when('/activity', {
			templateUrl: 'scripts/admin/activity.html', controller: 'ActivityCtrl' })
		.when('/buyer-reconciliation', {
			templateUrl: 'scripts/admin/buyer-reconciliation.html', controller: 'BuyerReconciliationCtrl' })
		.when('/', {
			templateUrl: 'scripts/admin/home.html', controller: 'DashboardController' })
		.otherwise({ redirectTo: '/'});
});